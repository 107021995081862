import React from 'react'
import { inject, observer } from 'mobx-react'

import { userStore     } from 'sdc-auth-user'

import { DataTable } from '../components/table'
import * as column   from '../components/table/columns'
import { col, num, text, date } from '../components/table/columns'

const columns = (store) => ([
  col('orderCode'),
  date({accessor:'orderDate'}),
  num('quantity'),
  num('colorCode'),
  col('colorName'),
  num('partCode'),
  text('partName'),
  col('code'),
])

export const ItemList = inject('itemsStore','partsStore')(observer(({itemsStore,partsStore}) =>
<DataTable store={itemsStore}
  columns={columns(itemsStore)}
  filter={item => item.part === partsStore.selected?.id}
  sorting={[{
    id   : 'colorName',
  },{
    id   : 'code',
  }]}
/>))
