/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateModel = /* GraphQL */ `
  subscription OnCreateModel($owner: String!) {
    onCreateModel(owner: $owner) {
      id
      name
      owner
      description
      lots
      parts
      disabled
      createdAt
      updatedAt
      lastUsed
    }
  }
`;
export const onUpdateModel = /* GraphQL */ `
  subscription OnUpdateModel($owner: String!) {
    onUpdateModel(owner: $owner) {
      id
      name
      owner
      description
      lots
      parts
      disabled
      createdAt
      updatedAt
      lastUsed
    }
  }
`;
export const onDeleteModel = /* GraphQL */ `
  subscription OnDeleteModel($owner: String!) {
    onDeleteModel(owner: $owner) {
      id
      name
      owner
      description
      lots
      parts
      disabled
      createdAt
      updatedAt
      lastUsed
    }
  }
`;
export const onCreateLot = /* GraphQL */ `
  subscription OnCreateLot {
    onCreateLot {
      id
      model
      part
      modelName
      partName
      partCode
      color
      colorName
      colorCode
      quantity
      inStock
      grabbed
      ordered
      obsolete
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateLot = /* GraphQL */ `
  subscription OnUpdateLot {
    onUpdateLot {
      id
      model
      part
      modelName
      partName
      partCode
      color
      colorName
      colorCode
      quantity
      inStock
      grabbed
      ordered
      obsolete
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteLot = /* GraphQL */ `
  subscription OnDeleteLot {
    onDeleteLot {
      id
      model
      part
      modelName
      partName
      partCode
      color
      colorName
      colorCode
      quantity
      inStock
      grabbed
      ordered
      obsolete
      createdAt
      updatedAt
    }
  }
`;
export const onCreateGrab = /* GraphQL */ `
  subscription OnCreateGrab($owner: String!) {
    onCreateGrab(owner: $owner) {
      id
      owner
      lot
      item
      stock
      quantity
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateGrab = /* GraphQL */ `
  subscription OnUpdateGrab($owner: String!) {
    onUpdateGrab(owner: $owner) {
      id
      owner
      lot
      item
      stock
      quantity
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteGrab = /* GraphQL */ `
  subscription OnDeleteGrab($owner: String!) {
    onDeleteGrab(owner: $owner) {
      id
      owner
      lot
      item
      stock
      quantity
      createdAt
      updatedAt
    }
  }
`;
export const onCreateOrder = /* GraphQL */ `
  subscription OnCreateOrder($owner: String!) {
    onCreateOrder(owner: $owner) {
      id
      owner
      code
      lots
      parts
      date
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateOrder = /* GraphQL */ `
  subscription OnUpdateOrder($owner: String!) {
    onUpdateOrder(owner: $owner) {
      id
      owner
      code
      lots
      parts
      date
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteOrder = /* GraphQL */ `
  subscription OnDeleteOrder($owner: String!) {
    onDeleteOrder(owner: $owner) {
      id
      owner
      code
      lots
      parts
      date
      createdAt
      updatedAt
    }
  }
`;
export const onCreateItem = /* GraphQL */ `
  subscription OnCreateItem($owner: String!) {
    onCreateItem(owner: $owner) {
      id
      code
      owner
      order
      orderCode
      orderDate
      part
      partCode
      partName
      color
      colorCode
      colorName
      quantity
      remaining
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateItem = /* GraphQL */ `
  subscription OnUpdateItem($owner: String!) {
    onUpdateItem(owner: $owner) {
      id
      code
      owner
      order
      orderCode
      orderDate
      part
      partCode
      partName
      color
      colorCode
      colorName
      quantity
      remaining
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteItem = /* GraphQL */ `
  subscription OnDeleteItem($owner: String!) {
    onDeleteItem(owner: $owner) {
      id
      code
      owner
      order
      orderCode
      orderDate
      part
      partCode
      partName
      color
      colorCode
      colorName
      quantity
      remaining
      createdAt
      updatedAt
    }
  }
`;
export const onCreateStock = /* GraphQL */ `
  subscription OnCreateStock($owner: String!) {
    onCreateStock(owner: $owner) {
      id
      owner
      part
      partName
      partCode
      color
      colorName
      colorCode
      quantity
      ordered
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateStock = /* GraphQL */ `
  subscription OnUpdateStock($owner: String!) {
    onUpdateStock(owner: $owner) {
      id
      owner
      part
      partName
      partCode
      color
      colorName
      colorCode
      quantity
      ordered
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteStock = /* GraphQL */ `
  subscription OnDeleteStock($owner: String!) {
    onDeleteStock(owner: $owner) {
      id
      owner
      part
      partName
      partCode
      color
      colorName
      colorCode
      quantity
      ordered
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePart = /* GraphQL */ `
  subscription OnCreatePart {
    onCreatePart {
      id
      name
      code
      lego
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePart = /* GraphQL */ `
  subscription OnUpdatePart {
    onUpdatePart {
      id
      name
      code
      lego
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePart = /* GraphQL */ `
  subscription OnDeletePart {
    onDeletePart {
      id
      name
      code
      lego
      createdAt
      updatedAt
    }
  }
`;
export const onCreateColor = /* GraphQL */ `
  subscription OnCreateColor {
    onCreateColor {
      id
      name
      code
      lego
      html
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateColor = /* GraphQL */ `
  subscription OnUpdateColor {
    onUpdateColor {
      id
      name
      code
      lego
      html
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteColor = /* GraphQL */ `
  subscription OnDeleteColor {
    onDeleteColor {
      id
      name
      code
      lego
      html
      createdAt
      updatedAt
    }
  }
`;
