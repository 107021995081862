import { action, autorun, observable, toJS } from 'mobx'

import _ from 'lodash'
import moment from 'moment'

import converter from 'xml-js'

import api from './api'

import { API, graphqlOperation } from 'aws-amplify'
import * as mutations     from '../graphql/mutations'
import * as queries       from '../graphql/queries'
import * as subscriptions from '../graphql/subscriptions'

import { userStore     } from 'sdc-auth-user'
import { subscribe     } from 'sdc-publish-subscribe'
import { requiredParam } from 'sdc-utilities'
import * as store        from 'sdc-mobx-stores'

import { ContentApi    } from 'sdc-cms-client'
import { editingMode   } from 'sdc-cms-writing'
import { editingType   } from 'sdc-cms-writing'
import { update, remove} from 'sdc-mobx-stores'

import { awsDataToEntry } from 'sdc-data-models'

import { AmplifyStore  } from '../amplify'

const typeID = 'K0mw3VFx49PaZnbOwZmETZcw2SarqPTF'

const parts = {
  '15712'  : '2555',
  '2339'   : '14395',
  '30241b' : '60475b',
  '3048c'  : '15571',
  '3068bpb0408' : '3068bp71',
  '3069bpx7' : '3069bpw2',
  '3069bpx40' : '3069bp0c',
  '4589b'  : '59900',
  '4623b'  : '88072',
  '553c'   : '30367c',
  '75c08'  : '22885',
  'x70'    : '2543',
  '98138pb013' : '98138p0d',
  '98138pb010' : '98138pc1',
}

const mappedPartCode = p => (parts[p] !== undefined) ? parts[p] : p


const reorder = (list,startIndex,endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex,1)
  result.splice(endIndex,0,removed)
  return result
}

class LotsStore extends AmplifyStore {

  @observable lotsByID    = {}

  @observable filtered    = []

  @observable suche    = ''

  @observable options = {}

  modelID = null
  partID  = null

  constructor({
    stateStore,
    modelsStore = requiredParam('modelsStore'),
    partsStore  = requiredParam('partsStore'),
    colorsStore = requiredParam('colorsStore'),
    ...options
  }) {
    super({
      ...options,
      typeID,
      name : 'lot',
    })
    this.clearViewing = false
    this.modelsStore  = modelsStore
    this.partsStore   = partsStore
    this.colorsStore  = colorsStore
    autorun(() => {
      if (!userStore.user.id) {
        this.clearData()
      }
    })
    autorun(() => {
      if (modelsStore.selected.id && this.modelID !== modelsStore.selected.id) {
        this.modelID = modelsStore.selected.id
        this.updateFiltered()
      }
    })
    autorun(() => {
      if (partsStore.selected.id && this.partID !== partsStore.selected.id) {
        this.partID  = partsStore.selected.id
        this.updateFiltered()
      }
    })

    this.createToEntry = awsDataToEntry('createLot')
    this.updateToEntry = awsDataToEntry('updateLot')
    this.deleteToEntry = awsDataToEntry('deleteLot')

    subscribe('network-changed', speed => {
      this.reload()
      this.subscribeToAWS()
    })

    subscribe('lot-entry-selected', this.lotSelected)
    subscribe('lot-entry-updated',  this.lotUpdated)
    subscribe('part-entry-updated', this.partUpdated)
  }

  subscribeToAWS = () => {
    this.subscribeTo('onCreateLot')
    this.subscribeTo('onUpdateLot')
    this.subscribeTo('onDeleteLot')
  }

  toggle = (option,value) => action(e => {
    if (option === 'dummy')
      this.options = {}
    else if (option === 'color')
      this.options = {
        ...this.options,
        color: value === this.options.color ? undefined : value,
      }
    else
      this.options = {
        color: this.options.color,
        [option] : value === undefined ? !this.options[option] : value,
      }
    console.log(toJS(this.options))
    this.suche = ''
  })

  handleClick = e => {
    e.preventDefault()
    editingMode.setMode('edit')()
  }

  @action
  clearData = () => {
    this.dataList   = []

    this.lotsByID = {}

    this.suche    = ''
  }

  @action
  createLot = e => {
    editingMode.setMode('edit')()
    this.create({
      model : this.modelsStore.selected.id,
    })()
  }

  @action
  createEmptyLot = e => {
    editingMode.setMode('edit')()
    this.typesStore.selectType(this.typeID)
    editingType.setTypeID(this.typeID)
    this.select({
      model: this.modelsStore.selected.id,
    })()
    this.editingStore.setSelected(this.selected)
    this.editingStore.setBuffers(this.selected)

    this.assignListener()
  }

  assignListener = () => {
    this.editingStore.assignmentListener = {
      assign: (entry,field,value,values) => {
        if (field?.key === 'part') {
          setTimeout(() => {
            this.editingStore.updateFieldWith(
              this.typesStore.fields.partName,
              undefined, this.partsStore.partsByID[value]?.name, true
            )
          }, 100)
          setTimeout(() => {
            this.editingStore.updateFieldWith(
              this.typesStore.fields.partCode,
              undefined, this.partsStore.partsByID[value]?.code, true
            )
          }, 200)
        }
        if (field?.key === 'color') {
          setTimeout(() => {
            this.editingStore.updateFieldWith(
              this.typesStore.fields.colorName,
              undefined, this.colorsStore.colorsByID[value]?.name, true
            )
          }, 100)
          setTimeout(() => {
            this.editingStore.updateFieldWith(
              this.typesStore.fields.colorCode,
              undefined, this.colorsStore.colorsByID[value]?.code, true
            )
          }, 200)
        }
      }
    }
  }

  reload = nextToken => {
    this.list({
      callback : this.parseAWS,
      params: {
        nextToken,
      },
    })()
  }

  mappedColor = c => {
    const code  = parseInt(c)
    const color = toJS(this.colorsStore.colorsByLego[code])
    return {
      color     : color?.id,
      colorName : color?.name,
      colorCode : color?.code === undefined ? code : color.code,
    }
  }

  mappedPart = code => {
    const part = toJS(this.partsStore.partsByLego[mappedPartCode(code)] || this.partsStore.partsByCode[mappedPartCode(code)])
    return {
      part     : part?.id,
      partName : part?.name,
      partCode : part?.code || code,
    }
  }

  fromMOC = item => ({
    ...this.mappedPart(item.ItemID._text),
    ...this.mappedColor(item.ColorID._text),
    quantity  : parseInt(item.Qty._text),
  })

  @action
  updateFrom = xml => {
    const json = converter.xml2json(xml, {compact: true, spaces: 4})
    const data = JSON.parse(json).BrickStoreXML.Inventory.Item.map(this.fromMOC)
    console.log(`read ${data.length} lots from file`)

    const obsolete = {}
    this.filtered.forEach(lot => {
      obsolete[lot.id] = true
    })

    const missing = _.uniq(data.filter(lot => !lot.part).map(lot => lot.partCode).map(mappedPartCode))
    missing.forEach(code => {
      console.log(`creating missing part ${code}`)
      this.partsStore.create({
        code,
      })()
    })
    console.log(`missing parts: ${missing.length}`)
    if (missing.length > 0) return;

    const noColor = _.uniq(data.filter(lot => !lot.color).map(lot => lot.colorCode))
    console.log(`missing colors: ${noColor.length}`)
    noColor.forEach(code => {
      console.log(`no color defined for code ${code}`)
    })
    if (noColor.length) {
      data.filter(lot => !lot.color).forEach(lot => {
        console.log(`${lot.quantity}x ${lot.partCode}:${lot.colorCode}`)
      })
      return;
    }

    data.filter(lot => !lot.part).forEach(lot => {
      console.log(`no part defined for code ${lot.partCode}`)
    })
    data.filter(lot => lot.part && lot.color).forEach(lot => {
      const match = _.find(this.filtered, {
        part  : lot.part,
        color : lot.color,
      })
      if (match) {
        obsolete[match.id] = false
        const delta = {}

        const addMismatch = (field,value) => {
          if (match[field] !== value) {
            delta[field] = value
          }
        }

        addMismatch('obsolete',false)

        addMismatch('modelName', this.modelsStore.selected.name)

        addMismatch('partName', lot.partName)
        addMismatch('partCode', lot.partCode)

        addMismatch('colorName', lot.colorName)
        addMismatch('colorCode', lot.colorCode)

        addMismatch('quantity', lot.quantity)

        if (Object.keys(delta).length) {
          setTimeout(action(() => {
            console.log(delta)
            this.updateEntryFields(match,delta)
          }), 100)
        }
      } else {
        const {id,...data} = lot
        console.log('creating missing lot')
        console.log(lot)
        this.create({
          model     : this.modelsStore.selected.id,
          modelName : this.modelsStore.selected.name,
          ...data,
        })()
      }
    })
    this.filtered.forEach(match => {
      if (obsolete[match.id] && !match.obsolete) {
        setTimeout(action(() => {
          console.log(`${match.partName}:${match.colorCode}: setting obsolete = true`)
          this.updateEntryField(match,'obsolete',true)
        }), 1000)
      }
    })
  }

  parseAWS = deferred => action(data => {
    if (data?.listLots?.items) {
      const payload = data.listLots.items
      if (payload) {
        this.dataList = orderedByPart(_.uniqBy([...this.dataList,...payload],item => item.id))
        console.log(`${payload.length} order lots loaded, total now ${this.dataList.length}`)
      }
      const token = data.listLots.nextToken
      if (token) {
        console.log(`loading more order items with ${token.substring(0,20)}...`)
        this.reload(token)
      }
      this.refreshData()
    }
    deferred.resolve(this.dataList)
  })

  @action
  filter = e => {
    this.suche = e.target.value
    this.options = {}
    this.updateFiltered()
  }

  filterBy = code => action(e => {
    this.suche = code
    this.options = {}
    this.updateFiltered()
  })

  @action
  updateFiltered = () => {
    let filtered = []
    if (this.modelID) {
      filtered = _.filter(this.dataList, lot => lot.model === this.modelID)
    }
    this.filtered = orderedByPart(filtered)
  }

  refreshData = () => {
    this.buildIndex()
    this.updateFiltered()
  }

  @action
  buildIndex = () => {
    this.lotsByID    = _.keyBy(this.dataList, 'id')
  }

  unlink = (field,id) => action(e => {

    const items = toJS(this.selected[field] || [])
    const index = items.indexOf(id)
    items.splice(index,1)

    this.editingStore.updateFieldWith(
      this.typesStore.fields[field],
      undefined, items, true
    )

  })

  afterCreate = entry => {
    this.setSelected(entry)
    this.typesStore.selectType(this.typeID)
    const field = this.typesStore.fields.kasten
    this.editingStore.setBuffers(entry)
    // this.editingStore.assign(field)({
    //   value: this.kastenStore.selected.id
    // })
    this.updateModelSize()
  }

  @action
  updateModelSize = () => {
    if (this.modelsStore.selected?.lots !== this.filtered.length) {
      this.modelsStore.updateEntryField(this.modelsStore.selected,'lots',this.filtered.length)
    }
  }

  lotSelected = lot => {
    this.assignListener()
  }

  partUpdated = part => {
    this.dataList.filter(lot => lot.part === part.id && lot.partName !== part.name).forEach(lot => {
      this.updateEntryFields(lot, {
        partName : part.name,
        partCode : part.code,
      })
    })
  }

  @action
  onCreateLot = lot => {
    if (lot) {
      this.dataList = orderedByPart(update(this.dataList)(lot))
      if (this.selected?.id === lot?.id) {
        this.selected = lot
        if (editingMode.isViewMode.get()) {
          this.editingStore.setSelected(this.selected)
          this.editingStore.setBuffers(this.selected)
        }
      }
      this.refreshData()
      this.updateModelSize()
    }
  }

  @action
  onDeleteLot = lot => {
    if (lot) {
      this.dataList = orderedByPart(remove(this.dataList)(lot))
      this.refreshData()
      this.updateModelSize()
    }
  }

  @action
  onUpdateLot = lot => {
    if (lot) {
      this.dataList = orderedByPart(update(this.dataList)(lot))
      this.refreshData()
    }
  }

}

export const orderedByModel = data => _.orderBy(data, ['colorName','modelName',])
export const orderedByPart  = data => _.orderBy(data, ['colorName','partName','partCode',])


export default ({...options}) => new LotsStore({...options,api:api()})
