import   React       from 'react'
import   moment      from 'moment'

import { Label          } from 'sdc-i18n-react'
import { languagesStore } from 'sdc-i18n-stores'
import { CreateButton, DeleteButton } from 'sdc-react-basics'

export const createDelete = ({type,onCreate,onDelete,canDelete}) => ({
  Header: onCreate && <CreateButton onClick={onCreate} title={type+'.create'} />,
  accessor: 'id',
  headerClassName: 'centered',
  className: 'centered',
  width: 30,
  sortable: false,
  resizable: false,
  Cell : cell => (typeof canDelete !== 'function' || canDelete(cell)) && onDelete ?
    <DeleteButton onClick={onDelete(cell.original)} title={type+'.delete'} /> : null,
})

export const name = () => ({
  Header: <Label value="field.name" />,
  accessor: 'name',
  headerClassName: 'left',
  sortable : true,
})

export const code = ({sortable=true}={}) => ({
  Header: <Label value="field.code" />,
  accessor: 'code',
  headerClassName: 'left',
  width: 160,
  sortable,
})

export const date = ({accessor = 'date'}={}) => ({
  Header: <Label value="field.date" />,
  accessor,
  headerClassName: 'left',
  width: 100,
  Cell: cell => <span>{cell.value ? moment(cell.value).format('DD.MM.YYYY') : ''}</span>
})

export const col = (field,{width=160,headerClassName='left',className}={}) => ({
  Header: <Label value={'field.'+field} />,
  accessor: field,
  headerClassName,
  className,
  width,
})

export const text = (field,{width,headerClassName='left',className}={}) => ({
  Header: <Label value={'field.'+field} />,
  accessor: field,
  headerClassName,
  className,
  width,
})

export const num = (field,{width=80,headerClassName='centered',className='right'}={}) => ({
  Header: <Label value={'field.'+field} />,
  accessor: field,
  headerClassName,
  className,
  width,
})

export const disabled = (field='disabled',{width=30,headerClassName='centered',className='centered',icon='ban'}={}) => ({
  accessor: field,
  headerClassName,
  className,
  width,
  Cell: cell => <span>{cell.value ? <i className={`fa fa-${icon} disabled`} /> : ''}</span>
})

export const lego = () => ({
  Header: <Label value="field.lego" />,
  accessor: 'lego',
  headerClassName: 'left',
  width: 160,
  sortable : true,
})
