import { toJS } from 'mobx'

import { API, graphqlOperation } from 'aws-amplify'
import * as mutations     from '../graphql/mutations'
import * as queries       from '../graphql/queries'
import * as subscriptions from '../graphql/subscriptions'

export default () => ({
  create : entry  => () => API.graphql(graphqlOperation(mutations.createLot, { input : toJS(entry) })),
  list   : params => () => API.graphql(graphqlOperation(  queries.listLots,  { limit: 1000, ...params })),
  update : delta  => () => {
    if (delta.id) {
      return API.graphql(graphqlOperation(mutations.updateLot, { input : toJS(delta) }))
    } else
      return API.graphql(graphqlOperation(mutations.createLot, { input : toJS(delta) }))
  },
  remove : entry  => () => API.graphql(graphqlOperation(mutations.deleteLot, { input : toJS({
    id: entry.id,
  }) })),
})
