import React from 'react'
import { observer } from 'mobx-react'

import { Label    } from 'sdc-i18n-react'

export const H3 = observer(({children,...props}) => <h3 {...props}>
  {children}
</h3>)

export const Label4 = observer(props => <Label as="h4" {...props} />)
