import { toJS } from 'mobx'

import { API, graphqlOperation } from 'aws-amplify'
import * as mutations     from '../graphql/mutations'
import * as queries       from '../graphql/queries'
import * as subscriptions from '../graphql/subscriptions'

export default () => ({
  create : entry  => () => API.graphql(graphqlOperation(mutations.createOrder, { input : toJS(entry) })),
  list   : params => () => API.graphql(graphqlOperation(  queries.listOrders,  { limit: 100 })),
  update : delta  => () => API.graphql(graphqlOperation(mutations.updateOrder, { input : toJS(delta) })),
  delete : entry  => () => {},
  patch  : delta  =>       API.graphql(graphqlOperation(mutations.updateOrder, { input : toJS(delta) })),
})
