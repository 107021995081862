import   React              from 'react'
import { inject, observer } from 'mobx-react'

import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'

import { userStore } from 'sdc-auth-user'
import { Label     } from 'sdc-i18n-react'

import { ModelName } from './name'

export const ModelMenu = inject('modelsStore')(observer(({modelsStore,nav}) => userStore.user.id ?
<UncontrolledDropdown>
  <DropdownToggle caret nav={!!nav} className="model-menu">
    <ModelName model={modelsStore.selected} />
  </DropdownToggle>
  <DropdownMenu>
    {modelsStore.dataList.filter(model => model.id && !model.disabled).map(model =>
      <DropdownItem key={model.id}
          className="model-menu-entry"
          active={model.id === modelsStore.selected.id}
          onClick={modelsStore.select(model)}>
        <div className="clearfix">
          {model.size && <span className="details pull-right">({model.size} <Label value="nav.zettel" />)</span>}
          <ModelName className="model-name" model={model} />
        </div>
      </DropdownItem>
    )}
  </DropdownMenu>
</UncontrolledDropdown> : null))
