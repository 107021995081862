import { action, observable } from 'mobx'

class UI {

  @observable theme = ''

  constructor() {
    this.switchTo()()
  }

  switchTo = (theme = 'darkGray') => action(e => {
    if (e?.preventDefault) {
      e.preventDefault()
    }

    if (this.theme !== theme) {
      console.log('switching to theme', theme)

      this.theme = theme || 'darkGray'

      document.body.className = 'zk zk-main '+this.theme
    }
  })

}

export const ui = new UI()
