import React from 'react'
import { inject, observer } from 'mobx-react'

import { editingMode   } from 'sdc-cms-writing'
import { Label         } from 'sdc-i18n-react'

import { Page          } from '../layout'
import { H3            } from '../components'

import { OrderList     } from './list'
import { OrderDetails  } from './details'

import { FileDropper   } from './dropper'

export const Orders = inject('ordersStore','itemsStore')(observer(({ordersStore,itemsStore}) => <Page>
  <h3 className="kasten">
    {editingMode.isEditMode.get() && <span className="toggle pull-right" onClick={editingMode.setMode('view')}><i className="fa fa-eye" /></span>}
    {editingMode.isViewMode.get() && <span className="toggle pull-right" onClick={editingMode.setMode('edit')}><i className="fa fa-edit" /></span>}
    <Label value="nav.orders" /> <span onClick={itemsStore.reload}>{itemsStore.dataList.length} lots</span>
  </h3>
  {<H3 className="zettel">
    <FileDropper store={ordersStore} />
  </H3>}
  <OrderList />
  <OrderDetails />
</Page>))
