import   React              from 'react'
import   _                  from 'lodash'
import { inject, observer } from 'mobx-react'

import { Container, Row, Col } from 'reactstrap'

import { LegoPart      } from '../components'

export const GrabbedItems = inject('partsStore','colorsStore','grabsStore','itemsStore','lotsStore')(observer(({partsStore,colorsStore,grabsStore,itemsStore,lotsStore}) =>
<Container fluid>
  {grabsStore.filtered.length > 0 && <Row>
    <Col>Grabbed from selected Order Item</Col>
  </Row>}
  <Row>
    {grabsStore.filtered.map(grab => {
      const item  = itemsStore.itemsByID[grab.item]
      if (!item) return null;
      const lot   = grab.lot ? lotsStore.lotsByID[grab.lot] : {}
      const part  = item.partCode  || partsStore.partsByID[item.part]?.code
      const color = item.colorCode || colorsStore.colorsByID[item.color]?.code
      return <LegoPart key={grab.id} width="12%" part={part} color={color} quantity={grab.quantity} border="#ccc">
        <div style={{fontSize: '10px', lineHeight: '1em'}}>
          {grab.stock && <div>to stock</div>}
          {grab.lot   && <div>{lot.modelName}</div>}
        </div>
      </LegoPart>
    })}
  </Row>
  </Container>))
