import React from 'react'
import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'

import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'

import './items.scss'

@inject('grabsStore','lotsStore','stocksStore')
@observer
export class ItemMenu extends React.Component {

  @observable dropdownOpen = false

  @action
  onMouseEnter = e => {
    this.dropdownOpen = true
  }

  @action
  onMouseLeave = e => {
    this.dropdownOpen = false
  }

  @action
  toggle = e => {
    this.dropdownOpen = !this.dropdownOpen
  }

  render() {
    const { item, grabbed, stocked, grabsStore, lotsStore, stocksStore } = this.props
    return <Dropdown className="item-menu"
        onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}
        isOpen={this.dropdownOpen} toggle={this.toggle} direction="down">
      <DropdownToggle tag="i" className="fa fa-bars" />
      <DropdownMenu>
        <DropdownItem disabled={item?.remaining === 0 || grabbed} onClick={grabsStore.grabFromOrder(item)}>
          <i className="fa fa-angle-double-left" /> grab to lot
        </DropdownItem>
        <DropdownItem disabled={!grabbed} onClick={grabsStore.toggle(lotsStore.selected,grabbed?.id)}>
          <i className="fa fa-edit" /> {grabbed?.quantity || '#'} grabbed
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem disabled={item?.remaining === 0} onClick={stocksStore.grabAllFrom(item)}>
          <i className="fa fa-caret-left" /> grab to stock
        </DropdownItem>
        <DropdownItem disabled={!stocked}>
          <i className="fa fa-edit" /> {stocked?.quantity || '#'} grabbed
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  }
}
