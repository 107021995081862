import React from 'react'
import { inject, observer } from 'mobx-react'

import { userStore     } from 'sdc-auth-user'

import { DataTable } from '../components/table'
import * as column   from '../components/table/columns'

const columns = (store) => ([
  column.createDelete({
    type     : store.name,
    onCreate : store.create({
    }),
  }),
  column.lego(),
  column.code(),
  column.name(),
  column.col('html'),
])

export const ColorList = inject('colorsStore')(observer(({colorsStore}) =>
<DataTable store={colorsStore}
  columns={columns(colorsStore)}
/>))
