import { action, autorun, observable, toJS } from 'mobx'

import _ from 'lodash'
import moment from 'moment'

import converter from 'xml-js'

import { API, graphqlOperation } from 'aws-amplify'
import * as mutations     from '../graphql/mutations'
import * as queries       from '../graphql/queries'
import * as subscriptions from '../graphql/subscriptions'

import { userStore     } from 'sdc-auth-user'
import { subscribe     } from 'sdc-publish-subscribe'
import { ContentApi    } from 'sdc-cms-client'
import { editingMode   } from 'sdc-cms-writing'
import { requiredParam } from 'sdc-utilities'
import { update        } from 'sdc-mobx-stores'

import { AmplifyStore  } from '../amplify'

import { awsDataToEntry } from 'sdc-data-models'

import api from './api'

const typeID = 'wreFo75WwJXLo0thNqewm35X9zQkrek6'

class OrdersStore extends AmplifyStore {

  @observable ordersByID   = {}
  @observable ordersByCode = {}

  reloading = null

  constructor({
    partsStore  = requiredParam('partsStore'),
    colorsStore = requiredParam('colorsStore'),
    itemsStore  = requiredParam('itemsStore'),
    ...options
  }) {
    super({
      ...options,
      typeID,
      name  : 'order',
      owned : true,
    })
    this.partsStore   = partsStore
    this.colorsStore  = colorsStore
    this.itemsStore   = itemsStore

    this.clearViewing = false
    autorun(() => {
      if (userStore.user.id) {
        this.reload()
        this.subscribeToAWS()
      } else {
        this.clearData()
      }
    })

    this.createToEntry = awsDataToEntry('createOrder')
    this.updateToEntry = awsDataToEntry('updateOrder')

    subscribe('network-changed', speed => {
      if (userStore.user.id) {
        this.reload()
        this.subscribeToAWS()
      }
    })

    subscribe('order-entry-created',  this.orderCreated)
    subscribe('order-entry-updated',  this.orderUpdated)
    subscribe('order-entry-selected', this.orderSelected)
  }

  reload = () => {
    this.list({
      callback : this.parseAWS
    })()
  }

  mappedColor = c => {
    const code  = parseInt(c)
    const color = toJS(this.colorsStore.colorsByLego[code])
    if (color === undefined) console.warn(`no color found for code ${code}`)
    return {
      color     : color?.id,
      colorName : color?.name,
      colorCode : color?.code !== undefined ? color?.code : code,
    }
  }

  mappedPart = code => {
    const part = toJS(this.partsStore.partsByLego[code] || this.partsStore.partsByCode[code])
    return {
      part     : part?.id,
      partName : part?.name,
      partCode : part?.code || code,
    }
  }

  fromOrder = order => {
    console.log(order)
    console.log(Array.isArray(order.ITEM))
    return ({
      code     : order.ORDERID._text,
      lots     : order.ORDERLOTS._text,
      parts    : order.ORDERITEMS._text,
      items    : this.orderItems(order).map(this.fromOrderItem),
      date     : moment(order.ORDERDATE._text, 'MM/DD/YYYY')
    })
  }

  orderItems = order => Array.isArray(order.ITEM) ? order.ITEM : [order.ITEM]

  fromOrderItem = item => ({
    code     : item.ORDERITEMID._text,
    ...this.mappedPart(item.ITEMID._text),
    ...this.mappedColor(item.COLOR._text),
    quantity : parseInt(item.QTY._text),
  })

  @action
  updateFrom = xml => {
    const raw = converter.xml2json(xml, {compact: true, spaces: 4})
    const json = JSON.parse(raw)
    console.log(json.ORDERS.ORDER)
    const orders = json.ORDERS.ORDER.length ? json.ORDERS.ORDER.map(this.fromOrder) : [this.fromOrder(json.ORDERS.ORDER)]

    orders.forEach(order => {
      console.log(`order ${order.code}: ${order.lots} lots, ${order.parts} parts`)

      const missing = _.uniq(order.items.filter(lot => !lot.part).map(lot => lot.partCode))
      missing.forEach(code => {
        console.log(`creating part ${code}`)
        this.partsStore.create({
          code,
        })()
      })
      const existing = this.ordersByCode[order.code]
      if (existing) {
        const update = toJS({
          ...existing,
          date : order.date.valueOf(),
        })
        if (!_.isEqual(toJS(existing),update)) {
          console.log(existing)
          console.log(update)
          this.update(update)
        }
        order.items.filter(item => item.color).forEach(item => {
          this.itemsStore.createOrUpdate({
            ...item,
            owner     : 'Google_'+userStore.user.id,
            order     : existing.id,
            orderCode : existing.code,
            orderDate : existing.date,
          })
        })
      } else {
        console.log(`creating order ${order.code}`)
        this.create({
          owner : 'Google_'+userStore.user.id,
          code  : order.code,
          lots  : order.lots,
          parts : order.parts,
          date  : order.date.valueOf(),
        })()
      }
    })
  }

  subscribeToAWS = () => {
    this.subscribeTo('onCreateOrder')
    this.subscribeTo('onUpdateOrder')
  }

  @action
  clearData = () => {
    this.ordersByID   = {}
    this.ordersByCode = {}

    this.dataList     = []
  }

  parseAWS = deferred => action(data => {
    if (data?.listOrders?.items) {
      const payload = data.listOrders.items

      this.buildIndices(payload)

      this.dataList   = _.orderBy(payload, 'name')
    }
    deferred.resolve(this.dataList)
  })

  @action
  buildIndices = payload => {
    this.ordersByID   = _.keyBy(payload, 'id')
    this.ordersByCode = _.keyBy(payload, 'code')
  }

  orderSelected = order => {
    if (order) {
      console.log(`selected ${order.code}`)
    }
  }

  orderUpdated = order => {
    if (order) {
      this.patch(order)
    }
  }

  patch = delta => {
    this.api.patch(delta).then(response => {
      this.onUpdateOrder(response?.data?.updateOrder)
    }).catch(error => {
      console.warn('update failed', error)
    })
  }

  @action
  onCreateOrder = order => {
    if (order) {
      this.dataList = update(this.dataList)(order)
      this.buildIndices(this.dataList)
    }
  }

  @action
  onUpdateOrder = order => {
    if (order) {
      this.dataList = update(this.dataList)(order)
      this.buildIndices(this.dataList)
    }
  }

}

export default ({...options}) => new OrdersStore({...options,api:api()})
