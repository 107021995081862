import React from 'react'
import { toJS        } from 'mobx'
import { inject, observer } from 'mobx-react'

import { Popover, PopoverHeader, PopoverBody, Input } from 'reactstrap'

import { PartIcon    } from '../components'
import { PartLabel   } from '../components'

export const GrabbedItem = inject('grabsStore','stocksStore')(observer(({grabsStore,stocksStore,grabbed,item,part,color}) => {

  const grabID  = 'grabbed-'+grabbed.id
  const stockID = 'stocked-'+stocksStore.selected.id

  const handleGrab = e => {
    if (e.key === 'Enter') {
      grabsStore.toggle(item,grabbed.id)(e)
      grabsStore.grabbedOnly(grabbed)(e,true)
    }
  }

  const handleStock = e => {
    if (e.key === 'Enter') {
      stocksStore.toggle(stockID)(e)
      stocksStore.stocked(e,true)
    }
  }

  const selectAll = e => {
    e.target.select()
  }

  return <div className="lego-part" style={{backgroundColor: 'white', margin: '2px', marginTop: '24px', color: 'black', padding: 0, textAlign: 'center', width: '108px'}}>
    <div style={{margin: 'auto', border: 'solid 4px #0a0', height: '100%'}}>
      <PartIcon  part={part} color={color} style={{cursor: 'not-allowed'}} onClick={stocksStore.restock(grabbed)}/>
      <PartLabel part={part} color={color} quantity={stocksStore.selected.quantity} />
      <div style={{fontSize: '10px', lineHeight: '1em'}}>
        <div id={grabID}  style={{cursor: 'pointer'}}>grabbed {grabbed.quantity}</div>
        <div id={stockID} style={{cursor: 'pointer'}}>
          { (stocksStore.selected.quantity > 0) && <span>{stocksStore.selected.quantity} in stock</span>}
          {!(stocksStore.selected.quantity > 0) &&  stocksStore.selected.id && <span style={{color: '#f99'}}>out of stock</span>}
          {!(stocksStore.selected.quantity > 0) && !stocksStore.selected.id && <span style={{cursor: 'zoom-in', color: '#fc9'}} onClick={stocksStore.createFor(item)}>maybe in stock</span>}
        </div>
        { stocksStore.selected.id && <div style={{cursor: 'pointer', color: '#090'}}>managed</div>}
        {!stocksStore.selected.id && <div style={{cursor: 'zoom-in', color: '#f99'}} onClick={stocksStore.createFor(item)}>unmanaged</div>}
      </div>
    </div>
    <Popover isOpen={grabsStore.modalState[grabbed.id]} toggle={grabsStore.toggle(item,grabbed.id)} target={grabID} placement="top">
      <PopoverHeader>Grabbing how many?</PopoverHeader>
      <PopoverBody>
        <Input style={{textAlign: 'center'}} value={grabbed.quantity} onChange={grabsStore.grabbedOnly(grabbed)} onKeyDown={handleGrab} autoFocus onFocus={selectAll} />
      </PopoverBody>
    </Popover>
    <Popover isOpen={stocksStore.modalState[stockID]} toggle={stocksStore.toggle(stockID)} target={stockID} placement="top">
      <PopoverHeader>Stocked how many?</PopoverHeader>
      <PopoverBody>
        <Input style={{textAlign: 'center'}} value={stocksStore.selected.quantity || 0} onChange={stocksStore.stocked} onKeyDown={handleStock} autoFocus onFocus={selectAll} />
      </PopoverBody>
    </Popover>
  </div>
}))
