import { action, autorun, observable, toJS } from 'mobx'

import _ from 'lodash'
import moment from 'moment'

import converter from 'xml-js'

import api from './api'

import { API, graphqlOperation } from 'aws-amplify'
import * as mutations     from '../graphql/mutations'
import * as queries       from '../graphql/queries'
import * as subscriptions from '../graphql/subscriptions'

import { userStore     } from 'sdc-auth-user'
import { subscribe     } from 'sdc-publish-subscribe'
import { requiredParam } from 'sdc-utilities'
import * as store        from 'sdc-mobx-stores'

import { ContentApi    } from 'sdc-cms-client'
import { editingMode   } from 'sdc-cms-writing'
import { editingType   } from 'sdc-cms-writing'
import { update        } from 'sdc-mobx-stores'

import { awsDataToEntry } from 'sdc-data-models'

import { AmplifyStore  } from '../amplify'


const typeID = 'K0mw3VFx49PaZnbOwZmETZcw2SarqPTF'

class ItemsStore extends AmplifyStore {

  @observable itemsByID   = {}
  @observable itemsByCode = {}
  @observable itemsByUID  = {}

  @observable suche    = ''

  @observable filtered = []

  constructor({
    stateStore,
    lotsStore = requiredParam('lotsStore'),
    ...options
  }) {
    super({
      ...options,
      typeID,
      name  : 'item',
      owned : true,
    })
    this.clearViewing = false
    this.lotsStore    = lotsStore
    autorun(() => {
      if (userStore.user.id) {
        this.reload()
        this.subscribeToAWS()
      } else {
        this.clearData()
      }
    })
    autorun(() => {
      if (lotsStore.selected.id) {
        this.buildFiltered()
      } else {
        this.clearFiltered()
      }
    })

    this.createToEntry = awsDataToEntry('createItem')
    this.updateToEntry = awsDataToEntry('updateItem')

    subscribe('network-changed', speed => {
      if (userStore.user.id) {
        this.reload()
        this.subscribeToAWS()
      }
    })

    subscribe('item-entry-created',  this.itemCreated)
    subscribe('item-entry-selected', this.itemSelected)
    subscribe('item-entry-updated',  this.itemUpdated)
    subscribe('part-entry-updated',  this.partUpdated)
  }

  @action
  buildFiltered = () => {
    const match = this.lotsStore.selected
    this.filtered = _.orderBy(this.dataList.filter(item => item.part === match.part && item.color === match.color),['code'],['desc'])
  }

  @action
  clearFiltered = () => {
    this.filtered = []
  }

  subscribeToAWS = () => {
    this.subscribeTo('onCreateItem')
    this.subscribeTo('onUpdateItem')
  }

  @action
  clearData = () => {
    this.dataList    = []

    this.itemsByID   = {}
    this.itemsByCode = {}
    this.itemsByUID  = {}

    this.suche    = ''
  }

  @action
  createOrUpdate = item => {
    const existing = toJS(this.itemsByCode[item.code])
    if (existing) {
      const update = toJS({
        ...existing,
        ...item,
      })
      if (!_.isEqual(toJS(existing),update)) {
        console.log(existing)
        console.log(update)
        this.update(update)
      }
    } else {
      this.create(item)()
    }
  }

  reload = nextToken => {
    this.list({
      callback : this.parseAWS,
      params: {
        nextToken,
      },
    })()
  }

  parseAWS = deferred => action(data => {
    if (data?.listItems?.items) {
      const payload = data.listItems.items
      if (payload) {
        this.dataList = _.uniqBy([...this.dataList,...payload],item => item.id)
        console.log(`${payload.length} order items loaded, total now ${this.dataList.length}`)
      }
      const token = data.listItems.nextToken
      if (token) {
        console.log(`loading more order items with ${token.substring(0,20)}...`)
        this.reload(token)
      } else {
        this.buildIndex()
      }
    }
    deferred.resolve(this.dataList)
  })

  @action
  filter = e => {
    this.suche = e.target.value
    this.updateFiltered()
  }

  @action
  updateFiltered = () => {
    let filtered = []
    if (this.suche.length > 1) {
      filtered = _.filter(this.dataList, z => (z.name || '').toLowerCase().indexOf(this.suche.toLowerCase()) >= 0)
    }
    this.filtered = _.orderBy(filtered, ['priority','name'])
  }

  @action
  buildIndex = () => {
    this.itemsByID   = _.keyBy(this.dataList, 'id')
    this.itemsByCode = _.keyBy(this.dataList, 'code')
    this.itemsByUID  = _.groupBy(this.dataList, i => i.partCode +':'+ i.colorCode)
  }

  itemCreated = item => {
    this.buildIndex()
  }

  itemUpdated = item => {
    this.buildIndex()
  }

  partUpdated = part => {
    this.dataList.filter(item => item.part === part.id && item.partName !== part.name).forEach(item => {
      this.updateEntryFields(item, {
        partName : part.name,
        partCode : part.code,
      })
    })
  }

  @action
  onCreateItem = item => {
    console.log(`onCreateItem()`)
    if (item) {
      this.dataList = update(this.dataList)(item)
      this.buildIndex()
    }
  }

  @action
  onUpdateItem = item => {
    if (item) {
      console.log(`onUpdateItem(): ${item.remaining}x ${item.partName}:${item.colorCode}`)
      this.dataList = update(this.dataList)(item)
      this.buildIndex()
    }
  }

  @observable obsolete = false

  toggleObsolete = action(e => {
    this.obsolete = !this.obsolete
  })

}

export default ({...options}) => new ItemsStore({...options,api:api()})
