import React from 'react'
import cn    from 'classnames'
import { inject, observer } from 'mobx-react'

import { Auth          } from 'aws-amplify'

import { Link, NavLink } from 'react-router-dom'
import { Nav, Input    } from 'reactstrap'


import { userStore     } from 'sdc-auth-user'
import { UserName      } from 'sdc-auth-react'

import { AppVersion, Button   } from 'sdc-react-basics'
import { Label, LanguagesMenu } from 'sdc-i18n-react'


import { uiBase           } from '../env/constants'

import { mode             } from '../layout/mode'

import { ModelMenu        } from '../models/menu'


export const NavBar = inject('lotsStore')(observer(({lotsStore}) => mode.state === 'menu' ?
<nav className="navbar navbar-expand-lg navbar-static-top">

  <div className="navbar-header">
    <Link className="navbar-brand" to={uiBase}>
      <span><i className="fa fa-fw fa-archive" /></span>
      <Label className="app-name" value="app.name" />
    </Link>
  </div>

  <ul className={cn('nav navbar-nav', !userStore.user.id && 'mr-auto')}>
    <li className="nav-item">
      <NavLink className="nav-link" to="/models">
        <i className={'fa fa-sitemap'}></i> <Label value="nav.models" />
      </NavLink>
    </li>
    <ModelMenu nav />
    <li className="nav-item">
      <NavLink className="nav-link" to="/lots">
        <i className={'fa fa-files-o'}></i> {lotsStore.filtered.length} <Label value="nav.lots" />
      </NavLink>
    </li>
    <li className="nav-item">
      <NavLink className="nav-link" to="/orders">
        <i className={'fa fa-shopping-cart'}></i> <Label value="nav.orders" />
      </NavLink>
    </li>
  </ul>

  {userStore.user.id && <div className="mr-auto zettel-suche">{false && <Input value="{zettelStore.suche}"
    onChange="{zettelStore.filter}" placeholder="Suche..."
  />}</div>}

  <AppVersion />

  <ul className="nav navbar-nav">
    <li className="nav-item">
      <NavLink className="nav-link" to="/parts">
        <i className={'fa fa-cogs'}></i> <Label value="nav.parts" />
      </NavLink>
    </li>
    <li className="nav-item">
      <NavLink className="nav-link" to="/colors">
        <i className={'fa fa-cogs'}></i> <Label value="nav.colors" />
      </NavLink>
    </li>
    {false && userStore.user.id && <li className="nav-item">
      <Button className="xmr-auto nav-link" caption="to AWS" icon="share" onClick="{zettelStore.migrateZettel}" />
    </li>}
    <li className="nav-item"><NavLink className="nav-link" to={'usersURL'}><UserName /></NavLink></li>
    <LanguagesMenu nav />
    {!userStore.user.id && <li className="nav-item"><Button className="nav-link" caption="action.login" onClick={() => Auth.federatedSignIn({provider: 'Google'})} /></li>}
    { userStore.user.id && <li className="nav-item"><Button className="nav-link"    icon="power-off"    onClick={() => Auth.signOut()} /></li>}
  </ul>

</nav>
: null))
