import { toJS } from 'mobx'

import { API, graphqlOperation } from 'aws-amplify'
import * as mutations     from '../graphql/mutations'
import * as queries       from '../graphql/queries'
import * as subscriptions from '../graphql/subscriptions'

export default () => ({
  create : entry  => () => API.graphql(graphqlOperation(mutations.createGrab, { input : toJS(entry) })),
  list   : params => () => API.graphql(graphqlOperation(  queries.listGrabs,  { limit: 1000 })),
  update : delta  => () => {
    if (delta.id)
      return API.graphql(graphqlOperation(mutations.updateGrab, { input : toJS(delta) }))
    else
      return API.graphql(graphqlOperation(mutations.createGrab, { input : toJS(delta) }))
  },
  remove : entry  => () => API.graphql(graphqlOperation(mutations.deleteGrab, { input : toJS({
    id: entry.id,
  }) })),
})
