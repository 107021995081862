import   React           from 'react'
import   moment          from 'moment'
import   cn              from 'classnames'
import { toJS          } from 'mobx'
import { inject, observer } from 'mobx-react'

import { Container, Row, Col } from 'reactstrap'
import { Popover, PopoverHeader, PopoverBody, Input } from 'reactstrap'


import { editingMode   } from 'sdc-cms-writing'
import { Label         } from 'sdc-i18n-react'

import { PartIcon      } from '../components'
import { PartLabel     } from '../components'

import { history       } from '..'

import { ItemMenu      } from './menu'


const sum = (acc, curr) => acc + curr

export const OrderItems = inject('ordersStore','partsStore','colorsStore','itemsStore','lotsStore','grabsStore','stocksStore')(observer(({ordersStore,partsStore,colorsStore,itemsStore,lotsStore,grabsStore,stocksStore}) =>
<Container fluid>
  <Row>
    <Col>
      {itemsStore.filtered.map(item => item.remaining === null ? item.quantity : item.remaining).reduce(sum,0)} ordered Items remaining
      {ordersStore.selected.id && <span>, {itemsStore.filtered.filter(item => item.order === ordersStore.selected.id).map(item => item.remaining === null ? item.quantity : item.remaining).reduce(sum,0)} selected</span>}
    </Col>
  </Row>
  <Row>
    {itemsStore.filtered.map(item => {
      const part  = item.partCode  || partsStore.partsByID[item.part]?.code
      const color = item.colorCode || colorsStore.colorsByID[item.color]?.code

      const grabbed = grabsStore.grabbedFromOrder(lotsStore.selected,item)
      const stocked = grabsStore.grabbedToStock(item)
      const ID = 'grabbed-'+grabbed?.id

      const handleEnter = e => {
        if (e.key === 'Enter') {
          grabsStore.toggle(item,grabbed.id)(e)
          grabsStore.grabbedOnly(grabbed)(e,true)
        }
      }

      const selectAll = e => {
        e.target.select()
      }

      const handleOrderClick = e => {
        grabsStore.selectItem(item)(e)
        ordersStore.select(ordersStore.ordersByCode[item.orderCode])(e)
      }

      const OrderCodeShowGrabs = () =>
        <div style={{fontSize: '10px', lineHeight: '1em', cursor: 'pointer'}}
            onClick={handleOrderClick} title="show grabs and order items">
          {item.orderCode}
        </div>

      const GrabbedRemainingAdd = () => item.remaining > 0 &&  stocksStore.selected.id ?
        <div style={{fontSize: '10px', lineHeight: '1em', cursor: 'move', color: '#0c0'}}
            onClick={stocksStore.grabRemaining(item)} title="transfer remaining to existing stock">
          {item.remaining} remaining
        </div>
      : null

      const GrabbedRemainingNew = () => item.remaining > 0 && !stocksStore.selected.id ?
        <div style={{fontSize: '10px', lineHeight: '1em', cursor: 'zoom-in', color: '#c00', fontWeight: 'bold'}}
            onClick={stocksStore.createFor(item)} title="transfer remaining to new stock">
          {item.remaining} remaining
        </div>
      : null

      const itemBorder = item => {
        if (item.missing    ) return '#c00';
        if (item.ordered    ) return '#068';
        if (item.overOrdered) return '#09c';
        if (item.stocked    ) return '#0a0';
        if (item.overStocked) return '#9f0';
        if (item.orderCode === ordersStore.selected?.code) return '#0af';
        return '#ccc'
      }


      let style   = {
        border : 'solid 4px '+ itemBorder(item)
      }
      if (item.orderCode === ordersStore.selected?.code) {
        style.backgroundColor = '#adf'
      }
      return <Col key={item.id} className="lego-part"
          style={{backgroundColor: 'white', margin: '2px', color: 'black', padding: 0, textAlign: 'center', minWidth: '13%', maxWidth: '13%'}}>
        <div id={ID} style={{...style, margin: 'auto', height: '100%', opacity: item.remaining === 0 ? '.33' : '1'}}>
          <PartIcon  part={part} color={color} />
          <PartLabel part={part} color={color} quantity={item.quantity} />
          <OrderCodeShowGrabs />
          {item.orderDate && <div style={{fontSize: '10px', lineHeight: '1em'}}>
            {moment(item.orderDate).format('DD.MM.YYYY')}
          </div>}
          {!item.missing && item.pending > 0 && <div style={{fontSize: '10px', lineHeight: '1em'}}>
            {item.ordPending >  0 && <span>order {item.ordPending} more</span>}
            {item.ordPending <= 0 && <span>grab  {item.pending}    {item.inStock > 0 && <span> more </span>}</span>}
          </div>}
          {grabbed?.quantity > 0 && <div style={{fontSize: '10px', lineHeight: '1em', cursor: 'not-allowed'}} onClick={grabsStore.remove(grabbed)}>
            {grabbed?.quantity} grabbed
          </div>}
          <GrabbedRemainingAdd />
          <GrabbedRemainingNew />
        </div>
        <ItemMenu item={item} grabbed={grabbed} stocked={stocked} />
        {grabbed?.id && <Popover isOpen={grabsStore.modalState[grabbed.id]} toggle={grabsStore.toggle(lotsStore.selected,grabbed?.id)} target={ID} placement="top">
          <PopoverHeader>Grabbing how many?</PopoverHeader>
          <PopoverBody>
            <Input style={{textAlign: 'center'}} value={grabbed.quantity} onChange={grabsStore.grabbedOnly(grabbed)} onKeyDown={handleEnter} autoFocus onFocus={selectAll} />
          </PopoverBody>
        </Popover>}
      </Col>
    })}
  </Row>
</Container>))
