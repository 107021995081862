/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getModel = /* GraphQL */ `
  query GetModel($id: ID!) {
    getModel(id: $id) {
      id
      name
      owner
      description
      lots
      parts
      disabled
      createdAt
      updatedAt
      lastUsed
    }
  }
`;
export const listModels = /* GraphQL */ `
  query ListModels(
    $filter: ModelModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        owner
        description
        lots
        parts
        disabled
        createdAt
        updatedAt
        lastUsed
      }
      nextToken
    }
  }
`;
export const getLot = /* GraphQL */ `
  query GetLot($id: ID!) {
    getLot(id: $id) {
      id
      model
      part
      modelName
      partName
      partCode
      color
      colorName
      colorCode
      quantity
      inStock
      grabbed
      ordered
      obsolete
      createdAt
      updatedAt
    }
  }
`;
export const listLots = /* GraphQL */ `
  query ListLots(
    $filter: ModelLotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLots(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        model
        part
        modelName
        partName
        partCode
        color
        colorName
        colorCode
        quantity
        inStock
        grabbed
        ordered
        obsolete
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGrab = /* GraphQL */ `
  query GetGrab($id: ID!) {
    getGrab(id: $id) {
      id
      owner
      lot
      item
      stock
      quantity
      createdAt
      updatedAt
    }
  }
`;
export const listGrabs = /* GraphQL */ `
  query ListGrabs(
    $filter: ModelGrabFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGrabs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        lot
        item
        stock
        quantity
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      owner
      code
      lots
      parts
      date
      createdAt
      updatedAt
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        code
        lots
        parts
        date
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getItem = /* GraphQL */ `
  query GetItem($id: ID!) {
    getItem(id: $id) {
      id
      code
      owner
      order
      orderCode
      orderDate
      part
      partCode
      partName
      color
      colorCode
      colorName
      quantity
      remaining
      createdAt
      updatedAt
    }
  }
`;
export const listItems = /* GraphQL */ `
  query ListItems(
    $filter: ModelItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        owner
        order
        orderCode
        orderDate
        part
        partCode
        partName
        color
        colorCode
        colorName
        quantity
        remaining
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getStock = /* GraphQL */ `
  query GetStock($id: ID!) {
    getStock(id: $id) {
      id
      owner
      part
      partName
      partCode
      color
      colorName
      colorCode
      quantity
      ordered
      createdAt
      updatedAt
    }
  }
`;
export const listStocks = /* GraphQL */ `
  query ListStocks(
    $filter: ModelStockFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStocks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        part
        partName
        partCode
        color
        colorName
        colorCode
        quantity
        ordered
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPart = /* GraphQL */ `
  query GetPart($id: ID!) {
    getPart(id: $id) {
      id
      name
      code
      lego
      createdAt
      updatedAt
    }
  }
`;
export const listParts = /* GraphQL */ `
  query ListParts(
    $filter: ModelPartFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listParts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        code
        lego
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getColor = /* GraphQL */ `
  query GetColor($id: ID!) {
    getColor(id: $id) {
      id
      name
      code
      lego
      html
      createdAt
      updatedAt
    }
  }
`;
export const listColors = /* GraphQL */ `
  query ListColors(
    $filter: ModelColorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listColors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        code
        lego
        html
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
