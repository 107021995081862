import React from 'react'
import { inject, observer } from 'mobx-react'
import { Provider         } from 'mobx-react'

import { Row, Col, Input  } from 'reactstrap'

import { Label            } from 'sdc-i18n-react'
import { EntryFieldsList  } from 'sdc-cms-editing'
import { editingType      } from 'sdc-cms-writing'


export const PartDetails = inject('partsStore')(observer(({partsStore}) => {

    if (editingType.typeID !== partsStore.typeID) return null

    const data = {
    store     : partsStore,
    dataStore : partsStore,
    update    : partsStore.update,
  }

  return <Provider {...data}><React.Fragment>
    <EntryFieldsList className="kasten-details" type="part" fields={['name','code','lego',]} />
  </React.Fragment></Provider>
}))
