import   React           from 'react'
import   cn              from 'classnames'
import   _               from 'lodash'
import   moment          from 'moment'
import { toJS          } from 'mobx'
import { inject, observer } from 'mobx-react'

import { Container, Row, Col, Input, InputGroup, InputGroupAddon } from 'reactstrap'

import { editingMode   } from 'sdc-cms-writing'
import { Label         } from 'sdc-i18n-react'
import { DeleteButton, Button } from 'sdc-react-basics'

import { Page          } from '../layout'
import { H3            } from '../components'

import { OrderItems    } from '../items'
import { StockItem     } from '../stocks'
import { StockItems    } from '../stocks/items'
import { GrabbedItems  } from '../grabs/items'

import { PartIcon      } from '../components'
import { PartLabel     } from '../components'

import { history       } from '..'

import { LotDetails    } from './details'
import { FileDropper   } from './dropper'

import { LegoPart      } from '../components'

import { borderColorFor } from '../utils'

import { orderedByModel, orderedByPart } from './store'


const sum = (acc, curr) => acc + curr


export const Lots = inject('modelsStore','partsStore','colorsStore','lotsStore','stocksStore','ordersStore','itemsStore')(observer(({modelsStore,partsStore,colorsStore,lotsStore,stocksStore,ordersStore,itemsStore}) => {

  const colorMatches = item => lotsStore.options.color === undefined || lotsStore.options.color === item.colorCode

  const filter = item => {
    if (lotsStore.suche.length && item.partCode && item.partCode.indexOf(lotsStore.suche) < 0) return false
    if (lotsStore.options.missing)    return !item.inStock && !item.ordered && colorMatches(item);
    if (lotsStore.options.completed)  return (item.inStock !== item.quantity) && !(item.ordered >= item.quantity - (item.inStock || 0)) && colorMatches(item);
    if (lotsStore.options.incomplete) return (item.inStock > 0 || item.ordered > 0) && item.inStock !== item.quantity && colorMatches(item);
    if (lotsStore.options.color !== undefined) return item.colorCode === lotsStore.options.color;
    return true;
  }

  let height = '45vh'
  if (lotsStore.suche.length) height = '120px';
  //if (lotsStore.options.completed || lotsStore.options.incomplete) height = '25vh';

  const selectAll = e => {
    e.target.select()
  }

  return <Page>
  <Container fluid>
    <Row>
      <Col md="3">
        <h3>
          {modelsStore.selected?.name}
        </h3>
      </Col>
      <Col md="3">
        <div>{lotsStore.filtered.length} lots, {lotsStore.filtered.filter(item => item.inStock >= item.quantity).length} in
        stock, {lotsStore.filtered.filter(item => (item.ordered || item.overOrdered) && !item.stocked && !item.overStocked).length} ordered</div>
        <div>{lotsStore.filtered.map(i => i.quantity).reduce(sum,0)} parts, {lotsStore.filtered.map(i => i.inStock).reduce(sum,0)} in
        stock, {lotsStore.filtered.filter(item => item.ordered && !item.stocked && !item.overStocked).map(i => i.ordered).reduce(sum,0)} ordered</div>
      </Col>
      <Col md="4">
        <div style={{fontSize: '2rem', lineHeight: '1.33em'}}>
          <InputGroup>
            <InputGroupAddon addonType="prepend"><Button style={{border: 'solid 1px #ccc', marginRight: '4px'}} icon="square-o"      onClick={lotsStore.toggle('missing')}    active={lotsStore.options.missing}    /></InputGroupAddon>
            <InputGroupAddon addonType="prepend"><Button style={{border: 'solid 1px #ccc', marginRight: '4px'}} icon="plus-square-o" onClick={lotsStore.toggle('completed')}  active={lotsStore.options.completed}  /></InputGroupAddon>
            <InputGroupAddon addonType="prepend"><Button style={{border: 'solid 1px #ccc', marginRight: '4px'}} icon="tasks"         onClick={lotsStore.toggle('incomplete')} active={lotsStore.options.incomplete} /></InputGroupAddon>
            <Input style={{marginTop: '5px'}} value={lotsStore.suche} onChange={lotsStore.filter} onFocus={selectAll} />
            <InputGroupAddon addonType="append" ><Button style={{border: 'solid 1px #ccc', marginLeft: '4px'}}  icon="times"         onClick={lotsStore.toggle('dummy')}      /></InputGroupAddon>
          </InputGroup>
        </div>
      </Col>
      <Col md="2">
        {modelsStore.selected.id && <H3 className="zettel">
          <FileDropper store={lotsStore} />
        </H3>}
      </Col>
    </Row>
    <Row>
      <Col md="10">
        <div style={{textAlign: 'right', fontSize: '1rem', lineHeight: '1.33em', marginBottom: '8px'}}>
          {_.uniq(_.orderBy(lotsStore.filtered, 'colorName').map(lot => lot.colorCode)).map(cc => {
            const color = colorsStore.colorsByCode[cc]
            const html  = color?.html
            return <Button title={color?.name} key={cc} className="color-filter" style={{height: '36px', display: 'inline-block', border: 'solid 1px #ccc', marginRight: '4px', paddingTop: '7px', backgroundColor: '#'+html}}
                onClick={lotsStore.toggle('color',cc)}    active={lotsStore.options.color === cc} >
              <span style={{width: '36px', display: 'inline-block', fontSize: '1rem'}}>{!html && cc}</span>
            </Button>
          })}
        </div>
      </Col>
    </Row>
  </Container>
  <div style={{height, overflowY: 'scroll'}}><Container fluid>
    <Row>
      {lotsStore.filtered.filter(filter).map(item => {
        const part  = item.partCode  || partsStore.partsByID[item.part]?.code
        const color = item.colorCode || colorsStore.colorsByID[item.color]?.code
        const style = {
          backgroundColor : item.id === lotsStore.selected.id ? '#adf' : undefined,
        }
        return <LegoPart key={item.id} width="6%" part={part} color={color} quantity={item.quantity} border={borderColorFor(item)} obsolete={item.obsolete} style={style}
                  onClick={lotsStore.select(item)} onPartCodeClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    partsStore.select(partsStore.partsByID[item.part])()
                    history.push('/parts')
                  }}
        >
            {item.inStock > 0 && item.quantity !== item.inStock && <div style={{fontSize: '10px', lineHeight: '1em'}}>
              {item.ordPending >  0 && <span>order {item.ordPending} more</span>}
              {item.quantity > item.inStock && <span style={{fontWeight: 'bold'}}>get {item.quantity - item.inStock}    {item.inStock > 0 && <span> more </span>}</span>}
              {item.quantity < item.inStock && <span>return {item.inStock - item.quantity} to stock</span>}
            </div>}
        </LegoPart>
      })}
    </Row>
  </Container></div>
  {lotsStore.selected.id && <Container fluid style={{marginTop: '8px'}}>
    <Row>
      <Col md="5">
        <LotDetails />
      </Col>
      <Col md="1">
        {lotsStore.selected.id && <div style={{textAlign: 'center'}}><DeleteButton style={{fontSize: '3rem', color: '#c66'}} onClick={lotsStore.remove(lotsStore.selected)} /></div>}
        {lotsStore.selected.id && <div style={{textAlign: 'center', fontSize: '2rem', lineHeight: '1em', marginTop: '4px'}}>{lotsStore.selected.partCode}</div>}
        {lotsStore.selected.id && lotsStore.selected.quantity === lotsStore.selected.inStock && <div style={{textAlign: 'center', fontSize: '2rem', lineHeight: '1em', color: '#585'}}>You are</div>}
        {lotsStore.selected.quantity - (lotsStore.selected.inStock) > lotsStore.selected.ordered && <div style={{textAlign: 'center', fontSize: '2rem', lineHeight: '1em', color: '#c96'}}>order</div>}
        {lotsStore.selected.quantity - (lotsStore.selected.inStock) > lotsStore.selected.ordered && <div style={{textAlign: 'center', fontSize: '4rem', lineHeight: '1em', color: '#c60'}}>
          {lotsStore.selected.quantity - (lotsStore.selected.inStock || 0) - lotsStore.selected.ordered}
        </div>}
        {lotsStore.selected.quantity - (lotsStore.selected.inStock) > lotsStore.selected.ordered && <div style={{textAlign: 'center', fontSize: '2rem', lineHeight: '1em', color: '#c96'}}>more</div>}
        {lotsStore.selected.quantity > lotsStore.selected.inStock && <div style={{textAlign: 'center', fontSize: '2rem', lineHeight: '1em', color: '#666'}}>grab</div>}
        {lotsStore.selected.quantity > lotsStore.selected.inStock && <div style={{textAlign: 'center', fontSize: '4rem', lineHeight: '1em'}}>{lotsStore.selected.quantity - lotsStore.selected.inStock}</div>}
        {lotsStore.selected.quantity > lotsStore.selected.inStock && <div style={{textAlign: 'center', fontSize: '2rem', lineHeight: '1em', color: '#666', marginTop: '-8px'}}>more</div>}
        {lotsStore.selected.id && lotsStore.selected.quantity === lotsStore.selected.inStock && <div style={{textAlign: 'center', fontSize: '2rem', lineHeight: '1em', color: '#585', marginTop: '-4px'}}>all set!</div>}
      </Col>
      <Col md="1">
        <StockItem />
      </Col>
      <Col md="5">
        <OrderItems />
      </Col>
    </Row>
    <Row>
      <Col md="6">
        <StockItems />
      </Col>
      <Col md="6">
        <GrabbedItems />
      </Col>
    </Row>
    {lotsStore.selected.id && <Row>
      <Col md="6">all lots</Col>
      <Col md="6" style={{fontSize: '24px', color: '#666'}}>{ordersStore.selected?.code && <span style={{cursor: 'pointer'}} onClick={itemsStore.toggleObsolete}>Items in order
        <span style={{color: '#fff'}}> -{((ordersStore.selected.code+'') || '???').substring(4)}</span>
        {ordersStore.selected?.date && <span> of <span style={{color: '#fff'}}>{moment(ordersStore.selected.date).format('DD.MM.YYYY')}</span></span>}
        {itemsStore.obsolete && <span style={{opacity: '.5'}}> (including emptied) </span>}
      </span>}</Col>
    </Row>}
    {lotsStore.selected.id && <Row>
      <Col md="6"><Container fluid><Row>
        {orderedByModel(lotsStore.dataList.filter(lot => lot.part === lotsStore.selected.part && lot.color === lotsStore.selected.color && !modelsStore.modelsByID[lot.model]?.disabled && (lot.quantity !== lot.inStock || lot.model === lotsStore.selected.model))).map(item => {
          const part  = item.partCode  || partsStore.partsByID[item.part]?.code
          const color = item.colorCode || colorsStore.colorsByID[item.color]?.code
          let style = {cursor: 'pointer'}
          if (item.model !== lotsStore.selected.model) style = {opacity: '.66'};
          if (item.id    === lotsStore.selected.id)    style = {opacity: '.33'};
          return <LegoPart key={item.id} width="12%" part={part} color={color} quantity={item.quantity} border={borderColorFor(item)} style={style}
              onClick={item.model === lotsStore.selected.model ? lotsStore.select(item) : undefined}
          >
            <div style={{fontSize: '10px', lineHeight: '1em'}}>
              {item.model !==lotsStore.selected.model && <div style={{cursor: 'pointer'}} onClick={modelsStore.select(modelsStore.modelsByID[item.model])}>{item.modelName}</div>}
              {item.ordPending >  0 && <span>order {item.ordPending} more</span>}
              {item.quantity > item.inStock && <span style={{fontWeight: 'bold'}}>get {item.quantity - item.inStock}    {item.inStock > 0 && <span> more </span>}</span>}
              {item.quantity < item.inStock && <span>return {item.inStock - item.quantity} to stock</span>}
            </div>
          </LegoPart>
        })}
      </Row>
      <Row>
        {orderedByModel(lotsStore.dataList.filter(lot => lot.part === lotsStore.selected.part && lot.color !== lotsStore.selected.color && !modelsStore.modelsByID[lot.model]?.disabled && lot.quantity !== lot.inStock)).map(item => {
          const part  = item.partCode  || partsStore.partsByID[item.part]?.code
          const color = item.colorCode || colorsStore.colorsByID[item.color]?.code
          let style = {cursor: 'pointer'}
          if (item.model !== lotsStore.selected.model) style = {opacity: '.66'};
          if (item.id    === lotsStore.selected.id)    style = {opacity: '.33'};
          return <LegoPart key={item.id} width="12%" part={part} color={color} quantity={item.quantity} border={borderColorFor(item)} style={style}
              onClick={item.model === lotsStore.selected.model ? lotsStore.select(item) : undefined}
          >
            <div style={{fontSize: '10px', lineHeight: '1em'}}>
              {item.model !==lotsStore.selected.model && <div style={{cursor: 'pointer'}} onClick={modelsStore.select(modelsStore.modelsByID[item.model])}>{item.modelName}</div>}
              {item.ordPending >  0 && <span>order {item.ordPending} more</span>}
              {item.quantity > item.inStock && <span style={{fontWeight: 'bold'}}>get {item.quantity - item.inStock}    {item.inStock > 0 && <span> more </span>}</span>}
              {item.quantity < item.inStock && <span>return {item.inStock - item.quantity} to stock</span>}
            </div>
          </LegoPart>
        })}
      </Row></Container></Col>
      <Col md="6"><Container fluid><Row>
        {ordersStore.selected?.code && orderedByPart(itemsStore.dataList.filter(item => item.order === ordersStore.selected.id && (itemsStore.obsolete || item.remaining === null || item.remaining > 0))).map(item => {
          const part  = item.partCode  || partsStore.partsByID[item.part]?.code
          const color = item.colorCode || colorsStore.colorsByID[item.color]?.code
          let border  = '#068'
          let style = { cursor: 'pointer', opacity: item.remaining === 0 ? '.33' : '1', }
          if (item.part === lotsStore.selected?.part && item.color === lotsStore.selected?.color) {
            border = '#0af'
            style = {
              ...style,
              backgroundColor : '#adf',
            }
          }
          if (item.remaining === 0) border = '#ccc'
          return <LegoPart key={item.id} width="12%" part={part} color={color} quantity={item.quantity} border={border} style={style}
              onClick={lotsStore.filterBy(item.partCode)}
          >
            <div style={{fontSize: '10px', lineHeight: '1em'}}>
              <div>{item.remaining !== null ? item.remaining : item.quantity} remaining</div>
            </div>
          </LegoPart>
        })}
      </Row></Container></Col>
    </Row>}
  </Container>}
</Page>
}))
