import React from 'react'
import { inject, observer } from 'mobx-react'

import { userStore     } from 'sdc-auth-user'

import { DataTable } from '../components/table'
import * as column   from '../components/table/columns'

const columns = (store) => ([
  column.createDelete({
    type     : store.name,
    onCreate : store.create({
    }),
    onDelete : store.remove,
  }),
  column.code(),
  column.lego(),
  column.name(),
])

export const PartList = inject('partsStore')(observer(({partsStore}) =>
<DataTable store={partsStore}
  columns={columns(partsStore)}
  filter={part => !partsStore.suche || part.code.indexOf(partsStore.suche) !== -1}
/>))
