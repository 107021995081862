export const borderColorFor = item => {

  let border  = '#f90'

  if (!item.inStock && !item.ordered) return '#f00';
  if (item.inStock === item.quantity) return '#0a0';
  if (item.inStock  >  item.quantity) return '#9f0';

  if (item.ordered) border = '#90c';
  if (item.ordered < item.quantity - (item.inStock || 0)) return '#f90';
  if (item.ordered > item.quantity - (item.inStock || 0)) return '#09c';

  return border

}
