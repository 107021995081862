import React from 'react'
import { inject, observer } from 'mobx-react'

import { Container, Row, Col, Input, InputGroup, InputGroupAddon } from 'reactstrap'

import { editingMode   } from 'sdc-cms-writing'
import { Label         } from 'sdc-i18n-react'

import { Page          } from '../layout'
import { H3            } from '../components'

import { LotList       } from '../lots/list'
import { ItemList      } from '../items/list'

import { PartList      } from './list'
import { PartDetails   } from './details'
import { FileDropper   } from './dropper'

export const Parts = inject('partsStore')(observer(({partsStore}) => <Page>
  <Container fluid>
    <Row>
      <Col md="6">
        <h3 className="kasten">
          <Label value="nav.parts" />
        </h3>
      </Col>
      <Col md="3">
        <div style={{fontSize: '2rem', lineHeight: '1.33em'}}>
          <InputGroup>
            <Input style={{marginTop: '5px'}} value={partsStore.suche} onChange={partsStore.filter}/>
          </InputGroup>
        </div>
      </Col>
      <Col md="3">
        <H3 className="zettel">
          <FileDropper store={partsStore} />
        </H3>
      </Col>
    </Row>
  </Container>
  <PartList />
  <PartDetails />
  <LotList />
  <ItemList />
</Page>))
