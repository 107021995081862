/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:9d596a52-df2e-4069-9354-263b000f064f",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_jgsMDAg6g",
    "aws_user_pools_web_client_id": "6c0frkk45fu9uv1i5mtba2eqmb",
    "oauth": {
        "domain": "mocshop-default.auth.eu-central-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://www.moc-shop.eu/",
        "redirectSignOut": "http://localhost:3000/,https://www.moc-shop.eu/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_appsync_graphqlEndpoint": "https://ybf37vwfe5ezriiro6wlbw656e.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
