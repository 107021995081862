import { action, autorun, observable, toJS } from 'mobx'

import _ from 'lodash'
import moment from 'moment'

import converter from 'xml-js'

import api from './api'

import { API, graphqlOperation } from 'aws-amplify'
import * as mutations     from '../graphql/mutations'
import * as queries       from '../graphql/queries'
import * as subscriptions from '../graphql/subscriptions'

import { userStore     } from 'sdc-auth-user'
import { subscribe     } from 'sdc-publish-subscribe'
import { requiredParam } from 'sdc-utilities'
import * as store        from 'sdc-mobx-stores'

import { ContentApi    } from 'sdc-cms-client'
import { editingMode   } from 'sdc-cms-writing'
import { editingType   } from 'sdc-cms-writing'
import { update        } from 'sdc-mobx-stores'

import { awsDataToEntry } from 'sdc-data-models'

import { AmplifyStore  } from '../amplify'


const typeID = 'K0mw3VFx49PaZnbOwZmETZcw2SarqPTF'

const sum = (a,b) => a + b

class GrabsStore extends AmplifyStore {

  modelID = null

  @observable filtered = []

  constructor({
    modelsStore = requiredParam('modelsStore'),
    lotsStore   = requiredParam('lotsStore'),
    itemsStore  = requiredParam('itemsStore'),
    ...options
  }) {
    super({
      ...options,
      typeID,
      name  : 'grab',
      owned : true,
    })
    this.clearViewing = false
    this.modelsStore  = modelsStore
    this.lotsStore    = lotsStore
    this.itemsStore   = itemsStore
    autorun(() => {
      if (userStore.user.id) {
        this.reload()
        this.subscribeToAWS()
      } else {
        this.clearData()
      }
    })

    this.createToEntry = awsDataToEntry('createGrab')
    this.updateToEntry = awsDataToEntry('updateGrab')
    this.deleteToEntry = awsDataToEntry('deleteGrab')

    subscribe('network-changed', speed => {
      if (userStore.user.id) {
        this.reload()
        this.subscribeToAWS()
      }
    })

    //subscribe('lot-entry-selected', this.lotSelected)
    //subscribe('lot-entry-updated',  this.lotUpdated)
    subscribe('lot-entry-selected', this.lotSelected)
  }

  subscribeToAWS = () => {
    this.subscribeTo('onCreateGrab')
    this.subscribeTo('onUpdateGrab')
    this.subscribeTo('onDeleteGrab')
  }

  @observable modalState = {}

  toggle = (lot,id) => action(e => {
    if (e.defaultPrevented) return;
    e.preventDefault()
    e.stopPropagation()

    this.modalState = {
      [id] : !this.modalState[id]
    }
    setTimeout(() => {
      this.lotsStore.select(this.lotsStore.lotsByID[lot.id])()
    },100)
  })

  @action
  clearData = () => {
    this.dataList   = []
    this.filtered   = []
  }

  selectItem = item => action(e => {
    e.preventDefault()
    e.stopPropagation()

    this.filtered = this.dataList.filter(grab => grab.item === item.id)
  })

  grabbedOnly = grab => action((e,forceUpdate) => {
    const quantity = parseInt(e.target.value)
    grab.quantity = quantity
    if (forceUpdate) {
      this.dataList = update(this.dataList)(grab)
      this.lotSelected(this.lotsStore.selected)
      this.updateEntryField(grab,'quantity',quantity)
    }
  })

  grabbedFromStock = lot => _.find(this.dataList, grab => grab.lot === lot.id && !grab.item)
  grabbedFromOrder = (lot,item) => _.find(this.dataList, grab => grab.lot === lot.id && grab.item === item.id)
  grabbedToStock   = item => _.find(this.dataList, grab => grab.stock && grab.item === item.id)

  grabToStock = (stock,item) => {
    this.create({
      owner    : 'Google_'+userStore.user.id,
      stock    : stock.id,
      item     : item.id,
      quantity : item.remaining || item.quantity,
    })()
  }

  grabFromStock = (lot,stock) => action(e => {
    const needed = lot.quantity - (lot.inStock || 0)
    const quantity = Math.min(needed, (stock.quantity || needed))
    console.log(`grabFromStock(): ${quantity}`)
    const existing = this.grabbedFromStock(lot)
    if (existing) {
      this.updateEntryField(existing,'quantity',quantity)
    } else {
      this.create({
        owner    : 'Google_'+userStore.user.id,
        lot      : lot.id,
        quantity,
      })()
    }
    //this.lotsStore.updateEntryField(lot,'inStock',lot.quantity)
    return quantity
  })

  grabFromOrder = item => action(e => {
    const lot = this.lotsStore.selected
    const existing = this.grabbedFromOrder(lot,item)
    console.log(toJS(existing))
    if (existing) {
      const ID = 'grabbed-'+existing.id
      this.toggle(lot,ID)(e)
      return;
    }

    e.preventDefault()
    e.stopPropagation()
    console.log(toJS(lot))
    const remaining = lot.quantity - (lot.inStock || 0)
    const quantity  = Math.min(remaining,item.remaining || item.quantity)
    console.log(`grabFromOrder(): ${quantity}/${lot.quantity} from ${item.remaining}/${item.quantity}`)
    if (quantity === 0) return
    if (existing) {
      //this.updateEntryField(existing,'quantity',lot.quantity)
    } else {
      this.create({
        owner    : 'Google_'+userStore.user.id,
        lot      : lot.id,
        item     : item.id,
        quantity,
      })()
      console.log(`grabFromOrder(): ${lot.inStock} => ${(lot.inStock || 0)+quantity}`)
      if (quantity === lot.quantity) {
        console.log('grabbing completed')
        this.lotsStore.updateEntryFields(lot,{
          inStock : (lot.inStock || 0)+quantity,
          ordered : 0,
        })
      } else {
        this.lotsStore.updateEntryField(lot,'inStock',(lot.inStock || 0)+quantity)
      }
    }
  })

  reload = () => {
    this.list({
      callback : this.parseAWS
    })()
  }

  @action
  parseAWS = deferred => action(data => {
    this.dataList = data?.listGrabs?.items || []
  })

  @action
  lotSelected = lot => {

    this.filtered = []

    if (!lot) return

    const grabbed = this.filteredCount(grab => grab.lot === lot.id)
    if (lot.inStock !== grabbed && grabbed > 0) {
      console.log(`lotSelected(): ${lot.inStock} => ${grabbed}`)
      this.lotsStore.updateEntryField(lot,'inStock',grabbed)
    }
  }

  @action
  onCreateGrab = grab => {
    if (grab) {
      console.log('onCreateGrab()')
      this.dataList = update(this.dataList)(grab)
      if (grab.lot) setTimeout(this.lotsStore.select(this.lotsStore.lotsByID[grab.lot]), 500);
      this.updateOrderItems(grab)
    }
  }

  @action
  onUpdateGrab = grab => {
    if (grab) {
      this.dataList = update(this.dataList)(grab)
      if (grab.lot) setTimeout(this.lotsStore.select(this.lotsStore.lotsByID[grab.lot]), 500);
      this.updateOrderItems(grab)
    }
  }

  @action
  onDeleteGrab = grab => {
    if (grab) {
      const grabbed = this.filteredCount(item => item.lot === grab.lot)
      console.log(`onDeleteGrab() is still ${grabbed}`)
      if (grab.lot) setTimeout(this.lotsStore.select(this.lotsStore.lotsByID[grab.lot]), 500);
      this.updateOrderItems(grab)
    }
  }

  updateOrderItems = grab => {
    if (!grab.item) return;
    const item = this.itemsStore.itemsByID[grab.item]
    if (!item) return;

    const grabbed = this.filteredCount(grab => grab.item === item.id)
    this.itemsStore.updateEntryField(item,'remaining', item.quantity - grabbed)
  }

  filteredCount = filter => this.dataList.filter(filter).map(grab => grab.quantity).reduce(sum,0)

}

export default ({...options}) => new GrabsStore({...options,api:api()})
