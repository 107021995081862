import axios  from 'axios'

import { makeTypesStore, makeControlsStore, makeHelpStore, makeIndexStore }  from 'sdc-cms-client'
import { editingMode      } from 'sdc-cms-writing'
import { makeEditingStore } from 'sdc-cms-writing'
import { makeCmsLanguagesStore, makeCmsLabelsStore } from 'sdc-i18n-cms'
import { languagesStore, labelsStore } from 'sdc-i18n-stores'
//import { adSettings, makeAdsStore } from 'sdc-amazon-ads'

import { publish          } from 'sdc-publish-subscribe'

import usersStore           from './data/users/store'

import makeColorsStore from './colors/store'
import makePartsStore  from './parts/store'

import makeStocksStore from './stocks/store'

import makeModelsStore from './models/store'
import makeLotsStore   from './lots/store'
import makeGrabsStore  from './grabs/store'

import makeOrdersStore from './orders/store'
import makeItemsStore  from './items/store'

export { authStore } from 'sdc-auth-store'
export { userStore } from 'sdc-auth-user'

languagesStore.selectLanguage('de')()

labelsStore.addTranslation('en.action.login','Login')
labelsStore.addTranslation('de.action.login','Anmelden')

editingMode.setMode('edit')()

const cmsBase = 'https://www.headless-cms.io/api/'
const spaceID = 'aWV9A28c094YGhStJ7CqKNsCFug8MZMi'
const backend = axios.create({
  withCredentials : true,
  baseURL         : cmsBase,
})

const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection
if (connection) {
  connection.addEventListener('change', () => {
    console.warn('connection changed to', connection.downlink, connection)
    if (connection.downlink === 0) {
      publish('network-lost')
    } else {
      publish('network-changed', connection.downlink)
    }
  })
}

//const adsStore        = makeAdsStore(       {cmsBase,typeID:'QeJc3lq7Xa0pWXMOqthQnfSBSbsHHmDM'})

export const cmsLanguagesStore = makeCmsLanguagesStore({ backend, spaceID })
export const cmsLabelsStore    = makeCmsLabelsStore(   { backend, typeID : 'NJuK959aE673w5tztY1HG5xtzVWaZSeM', accessKey : 'dhVeRC1zinviKPhQuMflKrohrM8WNM0L' })
export const typesStore        = makeTypesStore(       { backend })
export const editingStore      = makeEditingStore(     { backend, typesStore })
export const controlsStore     = makeControlsStore(    { backend, typeID : 'co38ne9yH0sOi8bZoxZ34IPOEyov7uAC' })
export const helpStore         = makeHelpStore(        { backend, labelsStore })

export const modelsStore       = makeModelsStore(      {          typesStore, editingStore })
export const colorsStore       = makeColorsStore(      {          typesStore, editingStore })
export const partsStore        = makePartsStore(       {          typesStore, editingStore })
export const lotsStore         = makeLotsStore(        {          typesStore, editingStore, modelsStore, partsStore, colorsStore })
export const itemsStore        = makeItemsStore(       {          typesStore, editingStore, lotsStore })
export const ordersStore       = makeOrdersStore(      {          typesStore, editingStore, partsStore, colorsStore, itemsStore })
export const grabsStore        = makeGrabsStore(       {          typesStore, editingStore, modelsStore, partsStore, colorsStore, itemsStore, lotsStore })
export const stocksStore       = makeStocksStore(      {          typesStore, editingStore, lotsStore, grabsStore })

export const indexStore        = makeIndexStore(       { backend, typesStore, editingStore, indexProvider : modelsStore })

//adSettings.setSize(160)

typesStore.setSpaceID(spaceID)

controlsStore.setTypeID('co38ne9yH0sOi8bZoxZ34IPOEyov7uAC')

export {
//  adsStore,
  usersStore,

  labelsStore,
  languagesStore,

//  adSettings,

//  booksStore,

//  postsStore,
//  categoriesCloud,
//  categoriesStore,
//  genresCloud,
//  genresStore,
}
