import { action, autorun, observable, toJS } from 'mobx'

import _ from 'lodash'
import moment from 'moment'

import converter from 'xml-js'

import api from './api'

import { API, graphqlOperation } from 'aws-amplify'
import * as mutations     from '../graphql/mutations'
import * as queries       from '../graphql/queries'
import * as subscriptions from '../graphql/subscriptions'

import { userStore     } from 'sdc-auth-user'
import { subscribe     } from 'sdc-publish-subscribe'
import { requiredParam } from 'sdc-utilities'
import * as store        from 'sdc-mobx-stores'

import { ContentApi    } from 'sdc-cms-client'
import { editingMode   } from 'sdc-cms-writing'
import { editingType   } from 'sdc-cms-writing'
import { update, remove} from 'sdc-mobx-stores'

import { awsDataToEntry } from 'sdc-data-models'

import { AmplifyStore  } from '../amplify'

const typeID = 'K0mw3VFx49PaZnbOwZmETZcw2SarqPTF'

class StocksStore extends AmplifyStore {

  @observable stocksByID    = {}

  @observable filtered    = []

  modelID = null
  partID  = null

  constructor({
    stateStore,
    lotsStore  = requiredParam('lotsStore'),
    grabsStore = requiredParam('grabsStore'),
    ...options
  }) {
    super({
      ...options,
      typeID,
      name  : 'lot',
      owned : true,
    })
    this.clearViewing = false
    this.lotsStore    = lotsStore
    this.grabsStore   = grabsStore
    autorun(() => {
      if (userStore.user.id) {
        this.reload()
        this.subscribeToAWS()
      } else {
        this.clearData()
      }
    })
    autorun(() => {
      if (lotsStore.selected.id) {
        this.buildFiltered()
      } else {
        this.clearFiltered()
      }
    })

    this.createToEntry = awsDataToEntry('createStock')
    this.updateToEntry = awsDataToEntry('updateStock')

    subscribe('network-changed', speed => {
      if (userStore.user.id) {
        this.reload()
        this.subscribeToAWS()
      }
    })

    subscribe('stock-entry-created',  this.stockCreated)
    subscribe('stock-entry-selected', this.stockSelected)
    subscribe('stock-entry-updated',  this.stockUpdated)
    subscribe('part-entry-updated',   this.partUpdated)
  }

  @action
  buildFiltered = () => {
    const match = this.lotsStore.selected
    if (this.partID !== match.part) {
      this.partID = match.part
      this.filtered = this.dataList.filter(item => item.part  === match.part)
    }
    this.selected = this.filtered.filter(item => item.color === match.color)?.[0] || {}
  }

  @action
  clearFiltered = () => {
    this.clearSelected()
  }

  subscribeToAWS = () => {
    this.subscribeTo('onCreateStock')
    this.subscribeTo('onUpdateStock')
  }

  @action
  clearData = () => {
    this.dataList     = []

    this.stocksByID   = {}
    this.stocksByUID  = {}
  }

  grabAllFrom = item => e => {
    if (this.selected.id)
      this.grabRemaining(item)(e)
    else
      this.createFor(item,item.remaining || item.quantity)(e)
  }

  createFor = (item,quantity) => action(e => {

    e.preventDefault()
    e.stopPropagation()

    this.create({
      owner     : 'Google_'+userStore.user.id,
      part      : item.part,
      partCode  : item.partCode,
      partName  : item.partName,
      color     : item.color,
      colorCode : item.colorCode,
      colorName : item.colorName,
      quantity,
    })()
  })

  @action
  createOrUpdate = item => {
    const existing = toJS(this.itemsByCode[item.code])
    if (existing) {
      const update = toJS({
        ...existing,
        ...item,
      })
      if (!_.isEqual(toJS(existing),update)) {
        console.log(existing)
        console.log(update)
        this.update(update)
      }
    } else {
      this.create(item)()
    }
  }

  reload = () => {
    this.list({
      callback : this.parseAWS
    })()
  }

  parseAWS = deferred => action(data => {
    if (data?.listStocks?.items) {
      const payload = data.listStocks.items
      if (payload) {
        this.dataList = payload
        this.buildIndex()
      }
    }
    deferred.resolve(this.dataList)
  })

  @action
  buildIndex = () => {
    this.stocksByID   = _.keyBy(this.dataList, 'id')
    this.stocksByUID  = _.groupBy(this.dataList, i => i.partCode +':'+ i.colorCode)

    this.partID = null
    this.buildFiltered()
  }

  @observable modalState = {}

  toggle = id => action(e => {
    if (e.defaultPrevented) return;
    e.preventDefault()
    e.stopPropagation()

    this.modalState = {
      [id] : !this.modalState[id]
    }
  })

  grabRemaining = item => action(e => {

    e.preventDefault()
    e.stopPropagation()

    if (!this.selected.id) return;

    const quantity = item.remaining || item.quantity
    console.log(`stock remaining ${quantity}`)

    this.addToQuantity(quantity)
    this.grabsStore.grabToStock(this.selected, item)
  })

  grabAll = lot => e => {
    const quantity = this.grabsStore.grabFromStock(lot,this.selected)(e)
    if (this.selected.quantity)
      this.updateQuantity(this.selected.quantity-quantity,true)
    else
      this.updateQuantity(0,true)
  }

  restock = grabbed => e => {
    if (this.selected.id) {
      this.addToQuantity(grabbed.quantity)
    }
    this.grabsStore.remove(grabbed)(e)
  }

  stocked = (e,forceUpdate) => {
    const quantity = parseInt(e.target.value)
    this.updateQuantity(quantity,forceUpdate)
  }

  addToQuantity = delta => {
    const quantity = (this.selected.quantity || 0) + delta
    this.updateQuantity(quantity,true)
  }

  @action
  updateQuantity = (quantity,forceUpdate) => {
    this.selected.quantity = quantity
    if (forceUpdate) {
      this.dataList = update(this.dataList)(this.selected)
      //this.lotSelected(this.lotsStore.selected)
      this.updateEntryField(this.selected,'quantity',quantity)
    }
  }

  stockCreated = item => {
    this.buildIndex()
  }

  stockUpdated = item => {
    this.buildIndex()
  }

  partUpdated = part => {
    this.dataList.filter(item => item.part === part.id && item.partName !== part.name).forEach(item => {
      this.updateEntryFields(item, {
        partName : part.name,
        partCode : part.code,
      })
    })
  }

  @action
  onCreateStock = stock => {
    console.log(`onCreateStock()`)
    if (stock) {
      this.dataList = update(this.dataList)(stock)
      this.buildIndex()
    }
  }

  @action
  onUpdateStock = stock => {
    if (stock) {
      this.dataList = update(this.dataList)(stock)
      this.buildIndex()
    }
  }

}


export default ({...options}) => new StocksStore({...options,api:api()})
