import   React       from 'react'
import { observer  } from 'mobx-react'

import { Col       } from 'reactstrap'

import { PartIcon  } from './icon'
import { PartLabel } from './label'

import './lego-part.scss'

export const LegoPart = observer(({width,children,className='',border='#ccc',part,color,quantity,obsolete,style={},onPartCodeClick,...props}) =>
<Col
  className={'lego-part '+className}
  style={{minWidth: width, maxWidth: width, ...style}}
  {...props}
>
  <div style={{margin: 'auto', border: 'solid 4px '+border, height: '100%', opacity: obsolete ? '.33' : '1'}}>
    <PartIcon  part={part} color={color} />
    <PartLabel part={part} color={color} quantity={quantity} onPartClick={onPartCodeClick} />
    {children}
  </div>
</Col>)
