import { action, autorun, observable, toJS } from 'mobx'

import _ from 'lodash'
import moment from 'moment'
import Parser from 'papaparse'

import { API, graphqlOperation } from 'aws-amplify'
import * as mutations     from '../graphql/mutations'
import * as queries       from '../graphql/queries'
import * as subscriptions from '../graphql/subscriptions'

import { userStore     } from 'sdc-auth-user'
import { subscribe     } from 'sdc-publish-subscribe'
import { ContentApi    } from 'sdc-cms-client'
import { editingMode   } from 'sdc-cms-writing'
import { update        } from 'sdc-mobx-stores'

import { ui            } from '../design'
import { AmplifyStore  } from '../amplify'

import { awsDataToEntry } from 'sdc-data-models'

import api from './api'

const typeID = 'k2SUt4acPPDeY2yg05lDaTBGMK3gjj6q'

class PartsStore extends AmplifyStore {

  @observable partsByID   = {}
  @observable partsByCode = {}
  @observable partsByLego = {}

  @observable suche       = ''

  reloading = null

  constructor(options) {
    super({
      ...options,
      typeID,
      name      : 'part',
    })
    this.clearViewing = false
    autorun(() => {
      if (userStore.user.id) {
        this.reload()
        this.subscribeToAWS()
      } else {
        this.clearData()
      }
    })

    this.createToEntry = awsDataToEntry('createPart')
    this.updateToEntry = awsDataToEntry('updatePart')
    this.deleteToEntry = awsDataToEntry('deletePart')

    subscribe('network-changed', speed => {
      if (userStore.user.id) {
        this.reload()
        this.subscribeToAWS()
      }
    })

    subscribe('part-entry-created',  this.partCreated)
    subscribe('part-entry-updated',  this.partUpdated)
    subscribe('part-entry-selected', this.partSelected)
  }

  reload = () => {
    this.list({
      callback : this.parseAWS
    })()
  }

  @action
  filter = e => {
    this.suche = e.target.value
//    this.options = {}
//    this.updateFiltered()
  }

  subscribeToAWS = () => {
    this.subscribeTo('onCreatePart')
    this.subscribeTo('onUpdatePart')
  }

  @action
  clearData = () => {
    this.partsByID   = {}
    this.partsByCode = {}
    this.partsByLego = {}
    this.dataList    = []
  }

  parseAWS = deferred => action(data => {
    if (data?.listParts?.items) {
      const payload = data.listParts.items

      this.dataList = _.orderBy(payload, ['name','code'])
      this.updateIndex()
    }
    deferred.resolve(this.dataList)
  })

  @action
  updateFrom = content => {
    const {data,errors,meta} = Parser.parse(content,{
      header : true,
    })
    if (meta.fields[0] !== 'part_num') return;
    data.map(({part_num: code,name,part_cat_id: category}) => ({code,name,category})).forEach(ref => {
      const part = this.partsByCode[ref.code]
      if (part !== undefined && part.name !== ref.name) {
        setTimeout(action(() => {
          console.log(`setting ${part.code} => ${ref.name}`)
          this.updateEntryField(part,'name',ref.name)
        }), 10)
      }
    })
  }

  @action
  updateIndex = () => {
    this.partsByID   = _.keyBy(this.dataList, 'id')
    this.partsByCode = _.keyBy(this.dataList, 'code')
    this.partsByLego = _.keyBy(this.dataList, 'lego')
  }

  partSelected = part => {
  }

  partUpdated = part => {
    if (part) {
      this.patch(part)
    }
  }

  patch = delta => {
    this.api.patch(delta).then(response => {
      this.onUpdatePart(response?.data?.updatePart)
    }).catch(error => {
      console.warn('update failed', error)
    })
  }

  @action
  onCreatePart = part => {
    if (part) {
      this.dataList = update(this.dataList)(part)
      this.updateIndex()
    }
  }

  @action
  onUpdatePart = part => {
    if (part) {
      this.dataList = update(this.dataList)(part)
      if (this.selected.id === part.id) {
        this.selected = part
      }
      this.updateIndex()
    }
  }

}

export default ({...options}) => new PartsStore({...options,api:api()})
