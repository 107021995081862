import React from 'react'
import { inject, observer } from 'mobx-react'
import { Provider         } from 'mobx-react'

import { Row, Col, Input  } from 'reactstrap'

import { Label            } from 'sdc-i18n-react'
import { EntryFieldsList  } from 'sdc-cms-editing'
import { editingType      } from 'sdc-cms-writing'


export const KastenDetails = inject('modelsStore')(observer(({modelsStore}) => {

    if (editingType.typeID !== modelsStore.typeID) return null

    const data = {
    store     : modelsStore,
    dataStore : modelsStore,
    update    : modelsStore.update,
  }

  return <Provider {...data}><React.Fragment>
    <EntryFieldsList className="kasten-details" type="model" fields={['name','disabled']} />
  </React.Fragment></Provider>
}))
