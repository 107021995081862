import React from 'react'
import { inject, observer } from 'mobx-react'

import { userStore     } from 'sdc-auth-user'

import { DataTable } from '../components/table'
import * as column   from '../components/table/columns'
import { col, num, text } from '../components/table/columns'

const columns = (store) => ([
  column.createDelete({
    type     : store.name,
    onDelete : store.remove,
  }),
  col('modelName'),
  num('quantity'),
  num('colorCode'),
  col('colorName'),
  num('partCode'),
  text('partName'),
  text('model'),
  col('obsolete'),
])

export const LotList = inject('lotsStore','partsStore')(observer(({lotsStore,partsStore}) =>
<DataTable store={lotsStore}
  columns={columns(lotsStore)}
  filter={item => item.part === partsStore.selected?.id}
  sorting={[{
    id   : 'modelName',
  },{
    id   : 'colorName',
  }]}
/>))
