import React from 'react'

const parts = {
  '3046a'   : '3046',
  '3068bpx24':'3068bpa0',
  '3068bpb0058':'3068bp73',
  '35336'   : '4176',
  '4079b'   : '4079',
  '4592c02' : '4592c01',
  '50340'   : '60471',
  '73436c01': '4343c01',
  '75c09'   : '76324',
  '75c10'   : '76348',
  '91968c01': '64424c01',
  'x1687'   : '57877',
}

const mappedPartCode = p => (parts[p] !== undefined) ? parts[p] : p

const partColors = {
  '3068bp72:15' : -1,
  '3068bp73:72' : -1,
  '4343c01:71'  : 4,
  '64424c01:0'  : -1,
}

const colors = {
  54 : 84,
}

const mappedPartColor = (p,c) => {
  const mc = (colors[c] !== undefined) ? colors[c] : c
  const partCode = mappedPartCode(p)
  const pc = `${partCode}:${mc}`
  return (partColors[pc] !== undefined) ? partColors[pc] : mc
}

export const PartIcon = ({part,color,...props}) =>
<div {...props}>
  <img width="85" height="85" src={`https://cdn.rebrickable.com/media/thumbs/parts/ldraw/${mappedPartColor(part,color)}/${mappedPartCode(part)}.png/85x85p.png`} />
</div>
