import { toJS } from 'mobx'

import { API, graphqlOperation } from 'aws-amplify'
import * as mutations     from '../graphql/mutations'
import * as queries       from '../graphql/queries'
import * as subscriptions from '../graphql/subscriptions'

export default () => ({
  create : entry  => () => API.graphql(graphqlOperation(mutations.createItem, { input : toJS(entry) })),
  list   : params => () => API.graphql(graphqlOperation(  queries.listItems,  { limit: 1000, ...params })),
  update : delta  => () => {
    if (delta.id)
      return API.graphql(graphqlOperation(mutations.updateItem, { input : toJS(delta) }))
    else
      return API.graphql(graphqlOperation(mutations.createItem, { input : toJS(delta) }))
  },
  delete : entry  => () => {},
})
