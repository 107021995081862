import   React              from 'react'
import   _                  from 'lodash'
import { inject, observer } from 'mobx-react'

import { Container, Row, Col } from 'reactstrap'

import { LegoPart      } from '../components'

export const StockItems = inject('partsStore','colorsStore','stocksStore')(observer(({partsStore,colorsStore,stocksStore}) =>
<Container fluid>
  {stocksStore.filtered.length > 0 && <Row>
    <Col>in stock</Col>
  </Row>}
  <Row>
    {_.orderBy(stocksStore.filtered,'colorName').map(item => {
      const part  = item.partCode  || partsStore.partsByID[item.part]?.code
      const color = item.colorCode || colorsStore.colorsByID[item.color]?.code
      const border = item.quantity ? '#ccc' : '#f99'
      return <LegoPart key={item.id} width="12%" part={part} color={color} quantity={item.quantity} border={border}>
        <div style={{fontSize: '10px', lineHeight: '1em'}}>
          { (item.quantity > 0) && <span>{item.quantity} in stock</span>}
          {!(item.quantity > 0) && <span>out of stock</span>}
        </div>
      </LegoPart>
    })}
  </Row>
  </Container>))
