import { action, autorun, observable, toJS } from 'mobx'

import _ from 'lodash'
import moment from 'moment'

import { API, graphqlOperation } from 'aws-amplify'
import * as mutations     from '../graphql/mutations'
import * as queries       from '../graphql/queries'
import * as subscriptions from '../graphql/subscriptions'

import { userStore     } from 'sdc-auth-user'
import { subscribe     } from 'sdc-publish-subscribe'
import { ContentApi    } from 'sdc-cms-client'
import { editingMode   } from 'sdc-cms-writing'
import { update        } from 'sdc-mobx-stores'

import { AmplifyStore  } from '../amplify'

import { awsDataToEntry } from 'sdc-data-models'

import api from './api'

const typeID = 'cSPUL490fwYOhKpFYup9L1gJLsKFl8CK'

class ColorsStore extends AmplifyStore {

  @observable colorsByID   = {}
  @observable colorsByCode = {}
  @observable colorsByLego = {}

  reloading = null

  constructor(options) {
    super({
      ...options,
      typeID,
      name      : 'color',
    })
    this.clearViewing = false
    autorun(() => {
      if (userStore.user.id) {
        this.reload()
        this.subscribeToAWS()
      } else {
        this.clearData()
      }
    })

    this.createToEntry = awsDataToEntry('createColor')
    this.updateToEntry = awsDataToEntry('updateColor')

    subscribe('network-changed', speed => {
      if (userStore.user.id) {
        this.reload()
        this.subscribeToAWS()
      }
    })

    subscribe('color-entry-created',  this.colorCreated)
    subscribe('color-entry-updated',  this.colorUpdated)
    subscribe('color-entry-selected', this.colorSelected)
  }

  reload = () => {
    this.list({
      callback : this.parseAWS
    })()
  }

  subscribeToAWS = () => {
    this.subscribeTo('onUpdateColor')
  }

  @action
  clearData = () => {
    this.colorsByID   = {}
    this.colorsByCode = {}
    this.colorsByLego = {}
    this.dataList     = []
  }

  parseAWS = deferred => action(data => {
    if (data?.listColors?.items) {
      const payload = data.listColors.items

      this.dataList = _.orderBy(payload, 'name')
      this.updateIndex()
    }
    deferred.resolve(this.dataList)
  })

  updateIndex = () => {
    this.colorsByID   = _.keyBy(this.dataList, 'id')
    this.colorsByCode = _.keyBy(this.dataList, 'code')
    this.colorsByLego = _.keyBy(this.dataList, 'lego')
  }

  colorSelected = color => {
  }

  colorUpdated = color => {
    if (color) {
      this.patch(color)
    }
  }

  patch = delta => {
    this.api.patch(delta).then(response => {
      this.onUpdateColor(response?.data?.updateColor)
    }).catch(error => {
      console.warn('update failed', error)
    })
  }

  @action
  onUpdateColor = color => {
    if (color) {
      this.dataList = update(this.dataList)(color)
      if (this.selected.id === color.id) {
        this.selected = color
      }
      this.updateIndex()
    }
  }

}

export default ({...options}) => new ColorsStore({...options,api:api()})
