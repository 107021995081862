import React from 'react'
import { observer  } from 'mobx-react'

import { Container, Row, Col } from 'reactstrap'

//import { LeftMenu  } from './menu/left'
//import { RightMenu } from './menu/right'


export const Page = observer(({children}) => <Container fluid>
  <Row>
    <Col className="sec-content-panel-wrapper">
      <div className="sec-content-panel">
        {children}
      </div>
    </Col>
  </Row>
</Container>)
