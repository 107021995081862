import React from 'react'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'

import { Popover, PopoverHeader, PopoverBody, Input, Col } from 'reactstrap'

import { GrabbedItem } from '../grabs/item'
import { LegoPart    } from '../components'
import { PartIcon    } from '../components'
import { PartLabel   } from '../components'

import { StockManaged   } from './managed'
import { StockUnmanaged } from './unmanaged'

import './stocks.scss'


export const StockItem = inject('partsStore','colorsStore','lotsStore','grabsStore','stocksStore')(observer(({partsStore,colorsStore,lotsStore,grabsStore,stocksStore}) => {

  const item  = lotsStore.selected
  if (!item.id) return null

  const part  = item.partCode  || partsStore.partsByID[item.part]?.code
  const color = item.colorCode || colorsStore.colorsByID[item.color]?.code

  const grabbed = grabsStore.grabbedFromStock(item)

  if (grabbed) return <GrabbedItem grabbed={grabbed} item={item} part={part} color={color} />

  const obsolete = (item.quantity === item.inStock && !item.obsolete)

  const ID = 'stocked-'+stocksStore.selected.id

  const handleEnter = e => {
    if (e.key === 'Enter') {
      stocksStore.toggle(ID)(e)
      stocksStore.stocked(e,true)
    }
  }

  const selectAll = e => {
    e.target.select()
  }

  return <Col className="lego-part"
        style={{backgroundColor: 'white', margin: '2px', marginTop: '24px', color: 'black', padding: 0, textAlign: 'center', width: '108px', opacity: obsolete ? '.33' : '1'}}>
    <div style={{margin: 'auto', border: 'solid 4px #0a0', height: '100%'}}>
      <PartIcon  part={part} color={color} style={{cursor: 'cell'}} onClick={(obsolete && false) ? undefined : stocksStore.grabAll(item)} title="grab from stock" />
      <PartLabel part={part} color={color} quantity={stocksStore.selected.quantity} />

      <div className="details">
        { stocksStore.selected.id && <div id={ID} style={{cursor: 'pointer'}} title="change quantity in stock">{stocksStore.selected.quantity} in stock</div>}
        {!stocksStore.selected.id && <div id={ID} style={{cursor: 'zoom-in', color: '#fc9'}} onClick={stocksStore.createFor(item)} title="create stock entry">maybe in stock</div>}
        <StockManaged   item={item} />
        <StockUnmanaged item={item} />
      </div>
    </div>
    {stocksStore.selected.id && <Popover isOpen={stocksStore.modalState[ID]} toggle={stocksStore.toggle(ID)} target={ID} placement="top">
      <PopoverHeader>Stocked how many?</PopoverHeader>
      <PopoverBody>
        <Input style={{textAlign: 'center'}} value={stocksStore.selected.quantity || 0} onChange={stocksStore.stocked} onKeyDown={handleEnter} autoFocus onFocus={selectAll} />
      </PopoverBody>
    </Popover>}
  </Col>
}))
