import React from 'react'
import { inject, observer } from 'mobx-react'
import { Provider         } from 'mobx-react'

import { Row, Col, Input  } from 'reactstrap'

import { Label            } from 'sdc-i18n-react'
import { EntryFieldsList  } from 'sdc-cms-editing'
import { editingType      } from 'sdc-cms-writing'


export const OrderDetails = inject('ordersStore')(observer(({ordersStore}) => {

    if (editingType.typeID !== ordersStore.typeID) return null

    const data = {
    store     : ordersStore,
    dataStore : ordersStore,
    update    : ordersStore.update,
  }

  return <Provider {...data}><React.Fragment>
    <EntryFieldsList className="kasten-details" type="order" fields={['name']} />
  </React.Fragment></Provider>
}))
