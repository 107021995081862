/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createModel = /* GraphQL */ `
  mutation CreateModel(
    $input: CreateModelInput!
    $condition: ModelModelConditionInput
  ) {
    createModel(input: $input, condition: $condition) {
      id
      name
      owner
      description
      lots
      parts
      disabled
      createdAt
      updatedAt
      lastUsed
    }
  }
`;
export const updateModel = /* GraphQL */ `
  mutation UpdateModel(
    $input: UpdateModelInput!
    $condition: ModelModelConditionInput
  ) {
    updateModel(input: $input, condition: $condition) {
      id
      name
      owner
      description
      lots
      parts
      disabled
      createdAt
      updatedAt
      lastUsed
    }
  }
`;
export const deleteModel = /* GraphQL */ `
  mutation DeleteModel(
    $input: DeleteModelInput!
    $condition: ModelModelConditionInput
  ) {
    deleteModel(input: $input, condition: $condition) {
      id
      name
      owner
      description
      lots
      parts
      disabled
      createdAt
      updatedAt
      lastUsed
    }
  }
`;
export const createLot = /* GraphQL */ `
  mutation CreateLot(
    $input: CreateLotInput!
    $condition: ModelLotConditionInput
  ) {
    createLot(input: $input, condition: $condition) {
      id
      model
      part
      modelName
      partName
      partCode
      color
      colorName
      colorCode
      quantity
      inStock
      grabbed
      ordered
      obsolete
      createdAt
      updatedAt
    }
  }
`;
export const updateLot = /* GraphQL */ `
  mutation UpdateLot(
    $input: UpdateLotInput!
    $condition: ModelLotConditionInput
  ) {
    updateLot(input: $input, condition: $condition) {
      id
      model
      part
      modelName
      partName
      partCode
      color
      colorName
      colorCode
      quantity
      inStock
      grabbed
      ordered
      obsolete
      createdAt
      updatedAt
    }
  }
`;
export const deleteLot = /* GraphQL */ `
  mutation DeleteLot(
    $input: DeleteLotInput!
    $condition: ModelLotConditionInput
  ) {
    deleteLot(input: $input, condition: $condition) {
      id
      model
      part
      modelName
      partName
      partCode
      color
      colorName
      colorCode
      quantity
      inStock
      grabbed
      ordered
      obsolete
      createdAt
      updatedAt
    }
  }
`;
export const createGrab = /* GraphQL */ `
  mutation CreateGrab(
    $input: CreateGrabInput!
    $condition: ModelGrabConditionInput
  ) {
    createGrab(input: $input, condition: $condition) {
      id
      owner
      lot
      item
      stock
      quantity
      createdAt
      updatedAt
    }
  }
`;
export const updateGrab = /* GraphQL */ `
  mutation UpdateGrab(
    $input: UpdateGrabInput!
    $condition: ModelGrabConditionInput
  ) {
    updateGrab(input: $input, condition: $condition) {
      id
      owner
      lot
      item
      stock
      quantity
      createdAt
      updatedAt
    }
  }
`;
export const deleteGrab = /* GraphQL */ `
  mutation DeleteGrab(
    $input: DeleteGrabInput!
    $condition: ModelGrabConditionInput
  ) {
    deleteGrab(input: $input, condition: $condition) {
      id
      owner
      lot
      item
      stock
      quantity
      createdAt
      updatedAt
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      owner
      code
      lots
      parts
      date
      createdAt
      updatedAt
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      owner
      code
      lots
      parts
      date
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      owner
      code
      lots
      parts
      date
      createdAt
      updatedAt
    }
  }
`;
export const createItem = /* GraphQL */ `
  mutation CreateItem(
    $input: CreateItemInput!
    $condition: ModelItemConditionInput
  ) {
    createItem(input: $input, condition: $condition) {
      id
      code
      owner
      order
      orderCode
      orderDate
      part
      partCode
      partName
      color
      colorCode
      colorName
      quantity
      remaining
      createdAt
      updatedAt
    }
  }
`;
export const updateItem = /* GraphQL */ `
  mutation UpdateItem(
    $input: UpdateItemInput!
    $condition: ModelItemConditionInput
  ) {
    updateItem(input: $input, condition: $condition) {
      id
      code
      owner
      order
      orderCode
      orderDate
      part
      partCode
      partName
      color
      colorCode
      colorName
      quantity
      remaining
      createdAt
      updatedAt
    }
  }
`;
export const deleteItem = /* GraphQL */ `
  mutation DeleteItem(
    $input: DeleteItemInput!
    $condition: ModelItemConditionInput
  ) {
    deleteItem(input: $input, condition: $condition) {
      id
      code
      owner
      order
      orderCode
      orderDate
      part
      partCode
      partName
      color
      colorCode
      colorName
      quantity
      remaining
      createdAt
      updatedAt
    }
  }
`;
export const createStock = /* GraphQL */ `
  mutation CreateStock(
    $input: CreateStockInput!
    $condition: ModelStockConditionInput
  ) {
    createStock(input: $input, condition: $condition) {
      id
      owner
      part
      partName
      partCode
      color
      colorName
      colorCode
      quantity
      ordered
      createdAt
      updatedAt
    }
  }
`;
export const updateStock = /* GraphQL */ `
  mutation UpdateStock(
    $input: UpdateStockInput!
    $condition: ModelStockConditionInput
  ) {
    updateStock(input: $input, condition: $condition) {
      id
      owner
      part
      partName
      partCode
      color
      colorName
      colorCode
      quantity
      ordered
      createdAt
      updatedAt
    }
  }
`;
export const deleteStock = /* GraphQL */ `
  mutation DeleteStock(
    $input: DeleteStockInput!
    $condition: ModelStockConditionInput
  ) {
    deleteStock(input: $input, condition: $condition) {
      id
      owner
      part
      partName
      partCode
      color
      colorName
      colorCode
      quantity
      ordered
      createdAt
      updatedAt
    }
  }
`;
export const createPart = /* GraphQL */ `
  mutation CreatePart(
    $input: CreatePartInput!
    $condition: ModelPartConditionInput
  ) {
    createPart(input: $input, condition: $condition) {
      id
      name
      code
      lego
      createdAt
      updatedAt
    }
  }
`;
export const updatePart = /* GraphQL */ `
  mutation UpdatePart(
    $input: UpdatePartInput!
    $condition: ModelPartConditionInput
  ) {
    updatePart(input: $input, condition: $condition) {
      id
      name
      code
      lego
      createdAt
      updatedAt
    }
  }
`;
export const deletePart = /* GraphQL */ `
  mutation DeletePart(
    $input: DeletePartInput!
    $condition: ModelPartConditionInput
  ) {
    deletePart(input: $input, condition: $condition) {
      id
      name
      code
      lego
      createdAt
      updatedAt
    }
  }
`;
export const createColor = /* GraphQL */ `
  mutation CreateColor(
    $input: CreateColorInput!
    $condition: ModelColorConditionInput
  ) {
    createColor(input: $input, condition: $condition) {
      id
      name
      code
      lego
      html
      createdAt
      updatedAt
    }
  }
`;
export const updateColor = /* GraphQL */ `
  mutation UpdateColor(
    $input: UpdateColorInput!
    $condition: ModelColorConditionInput
  ) {
    updateColor(input: $input, condition: $condition) {
      id
      name
      code
      lego
      html
      createdAt
      updatedAt
    }
  }
`;
export const deleteColor = /* GraphQL */ `
  mutation DeleteColor(
    $input: DeleteColorInput!
    $condition: ModelColorConditionInput
  ) {
    deleteColor(input: $input, condition: $condition) {
      id
      name
      code
      lego
      html
      createdAt
      updatedAt
    }
  }
`;
