import React from 'react'
import { inject, observer } from 'mobx-react'
import { Provider         } from 'mobx-react'

import { Row, Col, Input  } from 'reactstrap'

import { Label            } from 'sdc-i18n-react'
import { EntryFieldsList  } from 'sdc-cms-editing'
import { editingType      } from 'sdc-cms-writing'


export const ColorDetails = inject('colorsStore')(observer(({colorsStore}) => {

    if (editingType.typeID !== colorsStore.typeID) return null

    const data = {
    store     : colorsStore,
    dataStore : colorsStore,
    update    : colorsStore.update,
  }

  return <Provider {...data}><React.Fragment>
    <EntryFieldsList className="kasten-details" type="color" fields={['lego','code','name','html']} />
  </React.Fragment></Provider>
}))
