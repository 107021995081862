import { action, autorun, observable, toJS } from 'mobx'

import _ from 'lodash'
import moment from 'moment'

import { API, graphqlOperation } from 'aws-amplify'
import * as mutations     from '../graphql/mutations'
import * as queries       from '../graphql/queries'
import * as subscriptions from '../graphql/subscriptions'

import { userStore     } from 'sdc-auth-user'
import { subscribe     } from 'sdc-publish-subscribe'
import { ContentApi    } from 'sdc-cms-client'
import { editingMode   } from 'sdc-cms-writing'
import { update        } from 'sdc-mobx-stores'

import { AmplifyStore  } from '../amplify'

import { awsDataToEntry } from 'sdc-data-models'

import api from './api'

const typeID = 'wreFo75WwJXLo0thNqewm35X9zQkrek6'

class ModelsStore extends AmplifyStore {

  @observable modelsByID = {}

  @observable recently = []

  reloading = null

  constructor(options) {
    super({
      ...options,
      typeID,
      name  : 'model',
      owned : true,
    })
    this.clearViewing = false
    autorun(() => {
      if (userStore.user.id) {
        this.reload()
        this.subscribeToAWS()
      } else {
        this.clearData()
      }
    })

    this.createToEntry = awsDataToEntry('createModel')
    this.updateToEntry = awsDataToEntry('updateModel')

    subscribe('network-changed', speed => {
      if (userStore.user.id) {
        this.reload()
        this.subscribeToAWS()
      }
    })

    subscribe('model-entry-created',  this.modelCreated)
    subscribe('model-entry-updated',  this.modelUpdated)
    subscribe('model-entry-selected', this.modelSelected)
  }

  reload = () => {
    this.list({
      callback : this.parseAWS
    })()
  }

  subscribeToAWS = () => {
    this.subscribeTo('onUpdateModel')
  }

  @action
  clearData = () => {
    this.modelsByID = {}
    this.dataList   = []
  }

  parseAWS = deferred => action(data => {
    if (data?.listModels?.items) {
      const payload = data.listModels.items

      this.modelsByID = _.keyBy(payload, 'id')
      this.dataList   = _.orderBy(payload, 'name')

      this.updateRecently()
      if (this.recently.length > 0) {
        this.select(this.recently[0])()
      }
    }
    deferred.resolve(this.dataList)
  })

  @action
  updateRecently = () => {
    const recently = _.sortBy(this.dataList, z => z?.lastUsed || z?.created)
    this.recently  = recently.slice(-10).reverse()
  }

  modelSelected = model => {
    if (model) {
      const now = Math.floor(new Date().valueOf() / 1000)
      if (!model?.lastUsed || model.lastUsed + 10 < now) {
        setTimeout(this.setLastUsed(model,now), 50)
      }
    }
  }

  modelUpdated = model => {
    if (model) {
      this.patch(model)
    }
  }

  updateTheme = theme => {
    this.patch({
      id : this.selected.id,
      theme,
    })
  }

  setLastUsed = (model,ts) => action(() => {
    if ((model.lastUsed || 0) + 10 < ts) {
      this.updateEntryField(this.selected,'lastUsed',ts)
    }
  })

  patch = delta => {
    this.api.patch(delta).then(response => {
      this.onUpdateModel(response?.data?.updateModel)
    }).catch(error => {
      console.warn('update failed', error)
    })
  }

  @action
  onUpdateModel = model => {
    if (model) {
      this.dataList = update(this.dataList)(model)
      if (this.selected.id === model.id) {
        this.selected = model
      }
      this.modelsByID[model.id] = model
      this.updateRecently()
      if (this.recently.length > 0 && this.selected?.id !== this.recently[0].id) {
        this.select(this.recently[0])()
      }
    }
  }

}

export default ({...options}) => new ModelsStore({...options,api:api()})
