import React from 'react'
import { toJS             } from 'mobx'
import { inject, observer } from 'mobx-react'
import { Provider         } from 'mobx-react'

import { EntryFieldsList  } from 'sdc-cms-editing'

import { editingMode      } from 'sdc-cms-writing'
import { editingType      } from 'sdc-cms-writing'

import { mode             } from '../layout/mode'

export const LotDetails = inject('typesStore','lotsStore')(observer(({typesStore,lotsStore}) => {

  //if (!lotsStore.selected.id) return null
  if (editingType.typeID !== lotsStore.typeID) return null

  const data = {
    store     : lotsStore,
    dataStore : lotsStore,
    update    : lotsStore.update,
  }

  return <Provider {...data}><React.Fragment>
    <EntryFieldsList className="zettel-details" type="lot" fields={['part','color','quantity','inStock','ordered']} />
  </React.Fragment></Provider>
}))

