import React from 'react'
import { inject, observer } from 'mobx-react'

import { userStore     } from 'sdc-auth-user'

import { DataTable } from '../components/table'
import * as column   from '../components/table/columns'
import { num, date } from '../components/table/columns'

const columns = store => ([
  column.createDelete({
    type     : store.name,
    // onCreate : store.create({
    //   owner : 'Google_'+userStore.user.id,
    // }),
  }),
  date(),
  column.code({sortable:false}),
  num('lots'),
  num('parts'),
])

export const OrderList = inject('ordersStore')(observer(({ordersStore}) =>
<DataTable store={ordersStore}
  columns={columns(ordersStore)}
  sorting={[{
    id   : 'date',
    desc : true,
  }]}
/>))
