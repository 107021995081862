import   React, { useCallback } from 'react'

import { useDropzone   } from 'react-dropzone'

export const FileDropper = ({store}) => {

  const onDrop = useCallback(acceptedFiles => {
    acceptedFiles.forEach(file => {
      const reader = new FileReader()

      reader.onabort = () => console.log('aborted')
      reader.onerror = () => console.log('error')
      reader.onload  = () => {
        const content = reader.result
        store.updateFrom(content)
      }

      reader.readAsText(file)
    })
  })

  const { getRootProps, getInputProps } = useDropzone({onDrop})

  return <div {...getRootProps()}>
    <input {...getInputProps()} />
    <p style={{marginBottom: '8px'}}> drag BSX here </p>
  </div>

}
